import React, { useState  } from "react";
//import './Assesments2.css'
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap"
import { getAssesTimer, postAssesTimer,getAllCourses, getCourseVideos, getSections, getAllSections, uploadCourseDocs, getCourseDocs, postCourseDocs } from './../actions/asyncActions';
import { toast } from 'react-toastify';

const Addtimerquestions = (props) => {

    const location = props.location
    const [values, setValues] = useState([]);
    console.log("values",values)
    const [timer, setAssesTimer]= useState([]);
    //console.log("value",value)

    const onInputChange = e => {
        setValues({ ...values, [e.target.name]: e.target.value });
      };

    const handleSubmit = () => {
        
        const assestimer = {
         
          set_timer: values.set_timer,
          //section_id: selectedSectionId,
          //course_id: selectedCourseId,
         // video_id:selectedVideoId,
          //user_id: user.id
          
        }
        console.log("handleSubmit",assestimer)
     
    
        postAssesTimer(assestimer)
          .then(resp => {
            getAssesTimer()
            .then(resp => setAssesTimer(resp.assestimer))
            
            // setCourseVal(null)
            // setSectionVal(null)
            // setDocFile(null)
            //setQuestions({question:'',answer_a:'',answer_b:'',answer_c:'',answer_d:''});
    
            toast.success("Question added successfully", {
              position:
                toast.POSITION.TOP_CENTER, autoClose: 2000
            })
            
          })
        //   editUserVideos(data)
        //   .then(resp => {
        //     getUserVideos()
        //         .then(resp => setUserVideos(resp.data))
        //         console.log("resp",resp.data)
        //         // setStatus({ status:'incorrect'})
        //       })
    
          .catch(err => {
            console.log("Error", err);
          })
    
      }







    return (

        <div className="center" style={{textAlign:'center',paddingTop:'160px'}}>
            <CardBody>
                  <Col md={3}>
                        <div className="mb-3" style={{textAlign:'center'}}>
                          <Label htmlFor="formrow-aircraft-Input">Set Timer</Label>
                          <Input
                             type="number"
                             className="form-control"
                             value={values.set_timer}
                             onChange={onInputChange}
                             name="set_timer"
                             id="text" 
                           
                          />
                        </div>
                      </Col>
                      </CardBody>
                      <div >
                    <button className="btn btn-primary mr-1"
                    onClick={handleSubmit}
                    >Submit</button>
                    <button className="btn btn-secondary">Cancel</button>
                  </div>

            <span className="btn btn-pill btn-warning btn-air-warning btn-warning-gradien  text-light">Add Assesments</span>
            
        </div>
    )
}

export default Addtimerquestions
