import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../common/breadcrumb';
//import LearningFilter from './learning-filter';
//import learningOne from '../../assets/images/faq/learning-1.png';
//import VideoPoster from '../../assets/images/lightgallry/cat2.jpg';
//import four from '../../assets/images/blog/4.jpg';
//import learningDB from '../../data/learning/learningDB';
import {useVideo} from 'react-use';

import VideoPlayer from 'react-video-markers';
import { Button, Collapse } from 'reactstrap';

import { Player, LoadingSpinner, ControlBar, ReplayControl, ForwardControl, VolumeMenuButton, BigPlayButton } from 'video-react';
//import '~video-react/dist/video-react.css';
import { getCourseVideo, fetchLMSVideo, getCourseVideos, editUserVideos,getSections, getUserVideos,   postUserVideos, getQuestions ,getAllCourses} from '../../../actions/asyncActions';
import { HelpCircle } from 'react-feather';

import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const LearningDeatil = (props) => {
  const user = JSON.parse(localStorage.getItem("pm_users"));
    const location = props.location;
    const Videourl = location.state.url;
    const id = location.state ? location.state.id : 1
    const Mid = location.state ? location.state.Mid : 1
    const Mname = location.state.Mname;
    const Courseid = location.state.Courseid;
    
    console.log("Mname", Mname);
    const [count, setCount] = useState(0);
    const activeSection = 0;
    const [singleData, setSingleData] = useState('');
    const [isFilter, setIsFilter] = useState(true);

    const [courseVideos, setCourseVideos] = useState([]); 
    const [lmsVideo, setLMSvideo] = useState([]); 
    const [sections, setSections] = useState([]);
    const [userVideos, setUserVideos] = useState([]);
    console.log("Videosssssss",userVideos)
    const [payments, setPayment] = useState([]);
    const [courses, setCourses] = useState([]);
    const [allQuestions, setQuestions] = useState([]);
    const [CourseVidid, setCourseid] = useState('');
    const [data, setData]= useState(null)
    // const [singleData, setSingleData] = useState('');
    // const [singleData, setSingleData] = useState('');
    // const [Videourl, setvideo] = useState(''); 
    useEffect( () => {
      getAllCourses()
      .then(resp => setCourses(resp.data))
    }, []);

 useEffect( () => {
        getQuestions()
        .then(resp => setQuestions(resp.data))
      }, []);
      console.log("allQuestionsAsses",allQuestions)

    useEffect( () => {
        getCourseVideos()
        .then(resp => setCourseVideos(resp.data))
      }, []);

    useEffect( () => {
        getSections(Courseid)
        .then(resp => setSections(resp.data))
      }, []);

    useEffect( () => {
        getUserVideos()
        .then(resp => setUserVideos(resp.data))
      }, []);

    console.log("sections", sections);

    const courseSections = sections.filter(a => a.model_id === Mid);

    const sec_Courses = courseVideos.filter(a => a.model_id === Mid);

    

    // useEffect(() => {
    //     learningDB.filter((data) => {
    //         if (data.Id === id) {
    //             setSingleData(data)
    //         }
    //         return 0;
    //     })
    // }, [id]);

    const [video, state, controls, ref] = useVideo(
        <video src= {Videourl}
         autoPlay nofullscreen  height='50%' width='100%' controls/>
      );

      const [isPlaying, setState] = useState(false);
      const [volume, setVolumeState] = useState(0.7);
      
      const handlePlay = () => {
        setState({isPlaying: true});
      };
      
      const handlePause = () => {
        setState({isPlaying: false});
      };
      
      const handleVolume = value => {
        setVolumeState({volume: value});
      };

      const clickApply = (s) => {
        const user = JSON.parse(localStorage.getItem("pm_users"));
        const Mid = location.state ? location.state.Mid : 1;
        const Mname = location.state.Mname;
        const id = s.id
        const VideoName = s.video_name;
        console.log("VideoName", VideoName);
        const videoData = courseVideos.find(a => a.id === s.id);
        const courseId = videoData && videoData.course_id;
        const sectionId = videoData && videoData.section_id;
        const findSection = sections.find(a => a.section_id === sectionId);
        const modelId = findSection && findSection.model_id;

        // setvideo()
        // setId()
        // setModel()

        // getUserVideos()
        // .then(resp => {
        //     const findVideo = (resp.data).find(r => r.user_id === user.id && r.video_id === s.id && r.status === 'Incorrect')
        //     console.log("findVideowatched",findVideo)
        
        //     if (findVideo) {




        const Data ={
          status: 'watched',
          video_id: id,
          course_id: courseId,
          section_id: sectionId,
          model_id: modelId,
          user_id: user.id
        }
        console.log("Data", Data);
        
        fetchLMSVideo(VideoName)
        .then(resp => 
        {
            setLMSvideo(resp.data);
            console.log("resp", resp);
            console.log("RespData", resp.data);
            // setvideo(resp.data.url)
            props.history.push(`${process.env.PUBLIC_URL}/courses/course_learning`, { id, Mid, Mname, Courseid, url: resp.url, respData:resp.data });
        })
//find video usinf user id and video id

// getUserVideos()
// .then(resp => {
//     const findVideo = (resp.data).find(r => r.user_id === user.id && r.video_id === s.id && r.status === 'Incorrect')
//     console.log("findVideowatched",findVideo)

//     if (findVideo) {
//       // const data ={
//       //  // video_id: allQuestions[currentQuestion].qId,
//       //   status: 'watched',
     
//       //  }
//       const data ={
//         //video_id: allQuestions[currentQuestion].qId,
//          status: 'Incorrect',
     
//        }
//        console.log("Data", data);
//        editUserVideos(data)
//        .then(resp => {
//          getUserVideos()
//              .then(resp => setUserVideos(resp.data))
//              console.log("resp",resp.data)
//              // setStatus({ status:'incorrect'})
//            })
//            .catch(err => {
//              console.log("Error", err);
//            })
//    }
//  else{
        postUserVideos(Data)
        .then(resp => {
            getUserVideos()
            .then(resp => setUserVideos(resp.data))
          })
          .catch(err => {
            console.log("Error", err);
        })
      //}
    //})
    
    }
  
    const goBack = () => {
        props.history.push(`${process.env.PUBLIC_URL}/courses/course_models`);
     }

    // const handleExpand = (s, index) => {
    //   console.log("s",s.id)
    //   console.log("index",index+1)
    //   if(s.id === index+1){
    //     setIsFilter(!isFilter)
    //   } 
    // }
    const [isPlaying2, setState2] = useState(false);

    const handleExpand = panel => (event, expanded) => {
      setState2(expanded ? panel : null);

    };

    // const handleExpand = index => {
    //     if (index) {
    //        // to show the rest of the elements
    //        setIsFilter(!isFilter)
    //     }
    //     if (index === 0) {
    //         //  to show first item
    //         setIsFilter(!isFilter)
    //       }
    // }
    const paymentsFilter = payments.filter(s => s.user_id === user.id);

    const handleSubmit = (s) => {
      
      // const userAssesmentFilter = allQuestions.filter(s => s.user_id === user.id && s.course_id === course_id );
      // setCourseid(course_id);
      // console.log("course_id", course_id);
      //   console.log("userAssesmentFilter", userAssesmentFilter);
    }
    

     const goNext = (course_id) => {
        const user = JSON.parse(localStorage.getItem("pm_users"));
        //setCourseid(course_id)
        
        const Mid = location.state ? location.state.Mid : 1;
        const Mname = location.state.Mname;
        const id = props.location.state ? props.location.state.id : 1
        const userVideoFilter = userVideos.filter(s => s.user_id === user.id && s.course_id === Courseid && s.status === 'Incorrect');
        
        if (userVideoFilter.length > 0 && userVideoFilter.length <= count)
        {
          //const videoLength = userVideoFilter.length;
          setCount(count+1)
          const nextId = userVideoFilter[count+1].id;
          
        const sec_Courses = courseVideos.find(a => a.id === nextId);
        const VideoName = sec_Courses && sec_Courses.video_name;
        console.log("id", id);
        console.log("nextId", nextId);

        const videoData = courseVideos.find(a => a.id === nextId);
        const courseId = videoData && videoData.course_id;
        const sectionId = videoData && videoData.section_id;
        const findSection = sections.find(a => a.id === sectionId);
        const modelId = findSection && findSection.model_id;
        console.log("findSection", findSection);
        console.log("modelId", modelId);

        const Data ={
          status: 'watched',
          video_id: nextId,
          course_id: courseId,
          section_id: sectionId,
          model_id: modelId,
          user_id: user.id
        }
        console.log("Data", Data);

        fetchLMSVideo(VideoName)
        .then(resp => 
        {
            setLMSvideo(resp.data);
            console.log("resp", resp);
            console.log("RespData", resp.data);
            props.history.push(`${process.env.PUBLIC_URL}/courses/course_learning`, { id:nextId, Mid:modelId, Mname, Courseid, url: resp.url, respData:resp.data });
        })

        postUserVideos(Data)
        .then(resp => {
            getUserVideos()
            .then(resp => setUserVideos(resp.data))
          })
          .catch(err => {
            console.log("Error", err);
        })
     }
        
        
        else{
          
        const nextId = parseInt(id) + 1;
        const sec_Courses = courseVideos.find(a => a.id === nextId);
        const VideoName = sec_Courses && sec_Courses.video_name;
        console.log("id", id);
        console.log("nextId", nextId);

        const videoData = courseVideos.find(a => a.id === nextId);
        const courseId = videoData && videoData.course_id;
        const sectionId = videoData && videoData.section_id;
        const findSection = sections.find(a => a.id === sectionId);
        const modelId = findSection && findSection.model_id;
        console.log("findSection", findSection);
        console.log("modelId", modelId);

        const Data ={
          status: 'watched',
          video_id: nextId,
          course_id: courseId,
          section_id: sectionId,
          model_id: modelId,
          user_id: user.id
        }
        console.log("Data", Data);

        fetchLMSVideo(VideoName)
        .then(resp => 
        {
            setLMSvideo(resp.data);
            console.log("resp", resp);
            console.log("RespData", resp.data);
            props.history.push(`${process.env.PUBLIC_URL}/courses/course_learning`, { id:nextId, Mid:modelId, Mname, Courseid, url: resp.url, respData:resp.data });
        })

        postUserVideos(Data)
        .then(resp => {
            getUserVideos()
            .then(resp => setUserVideos(resp.data))
          })
          .catch(err => {
            console.log("Error", err);
        })
     }
        }
        
        //const sec_Courses = courseVideos.find(a => a.id === nextId && a.model_id === Mid );
    //     const sec_Courses = courseVideos.find(a => a.id === nextId);
    //     const VideoName = sec_Courses && sec_Courses.video_name;
    //     console.log("id", id);
    //     console.log("nextId", nextId);

    //     const videoData = courseVideos.find(a => a.id === nextId);
    //     const courseId = videoData && videoData.course_id;
    //     const sectionId = videoData && videoData.section_id;
    //     const findSection = sections.find(a => a.id === sectionId);
    //     const modelId = findSection && findSection.model_id;
    //     console.log("findSection", findSection);
    //     console.log("modelId", modelId);

    //     const Data ={
    //       status: 'watched',
    //       video_id: nextId,
    //       course_id: courseId,
    //       section_id: sectionId,
    //       model_id: modelId,
    //       user_id: user.id
    //     }
    //     console.log("Data", Data);

    //     fetchLMSVideo(VideoName)
    //     .then(resp => 
    //     {
    //         setLMSvideo(resp.data);
    //         console.log("resp", resp);
    //         console.log("RespData", resp.data);
    //         props.history.push(`${process.env.PUBLIC_URL}/courses/course_learning`, { id:nextId, Mid:modelId, Mname, Courseid, url: resp.url, respData:resp.data });
    //     })

    //     postUserVideos(Data)
    //     .then(resp => {
    //         getUserVideos()
    //         .then(resp => setUserVideos(resp.data))
    //       })
    //       .catch(err => {
    //         console.log("Error", err);
    //     })
    //  }

    const clickAssesments = (id) => {
      //console.log("click assesment id",id)
        props.history.push(`${process.env.PUBLIC_URL}/assesments2/assesments2`, { id });
    }
    const clickImageAssesments = (id) => {
      //console.log("click assesment id",id)
        props.history.push(`${process.env.PUBLIC_URL}/imageassesments/imageassesments`, { id });
    }
    const clickMultiAssesments = (id) => {
      //console.log("click assesment id",id)
        props.history.push(`${process.env.PUBLIC_URL}/multipleassesment/multipleassesments`, { id });
    }

    const clickDocuments = (id) => {
        props.history.push(`${process.env.PUBLIC_URL}/courses/docView`, { id });
    }

    const [activeIndex, setActiveIndex] = useState(null);

    return (
        <Fragment>
            <Breadcrumb title="Detail Course" parent="Learning" />
            <div className="container-fluid">
                
                <div className="media header-faq pull-center">
                  <button className="btn btn-square btn-outline-info btn-sm" onClick={goBack} >Back</button>
                  <h5 align='center' style={{marginLeft:"10px"}}>{ Mname }</h5>
                </div>
               {/* <div className="header-faq">
                        <button className="btn btn-square btn-outline-info btn-sm" onClick={goBack} >Back</button>
                        <h5 align='center'>{ Mname }</h5>
                </div> */}
                <div className="row">
                    <div className="col-xl-9 xl-60">

                        <div>
                            <Player
                              autoPlay playsInline
                              // startTime={50}
                              src={Videourl}
                            >
                              <BigPlayButton position="center" />
                              <LoadingSpinner />
                              <ControlBar>
                                <VolumeMenuButton />
                                <ReplayControl seconds={5} order={2.1} />
                                <ForwardControl seconds={5} order={3.1} />
                              </ControlBar>
                            </Player>
                        </div>
                        <div className="header-faq" style={{marginTop:"10px"}}>
                            <button className="btn btn-square btn-outline-info btn-sm" onClick={goNext} >Next >></button>
                        </div>

                    </div>

                   {/* <LearningFilter /> */}
                

        <div className="col-xl-3 xl-40">
                <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
                    <div className="row">
                        <div className="col-xl-12">

                          {courseSections.map((s, index) => {
                            const courseList = courseVideos.filter(a => a.section_id === s.id);
                            // {paymentsFilter.map((n, i) => {
                            //   const aaa = courses.find(s => s.id === n.course_id);
                            // const aaa = courses.find(s => s.id === n.course_id);
                            // const found = courseVideos.find(a => a.id === id);
                            // console.log("found", found);
                                return (  
                                    <div className="card">

                                      <div className="card-header">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link pl-0" data-toggle="collapse" 
                                               // onClick={() => setIsFilter(!isFilter)} 
                                               // onClick={() => handleExpand(s, index)} 
                                               onClick={event => setActiveIndex(
                                                    activeIndex === index ? null : index
                                                )}
                                              data-target="#collapseicon" 
                                              aria-expanded="false"
                                              // aria-expanded={isFilter} 
                                              // aria-expanded={(s.id === index+1) ?isFilter : null} 
                                              // aria-expanded={(activeSection === index) ? isFilter : null}
                                              aria-controls="collapseicon"
                                              >
                                              {s.section_name}
                                            </button>
                                        </h5>
                                    </div>

                                    <Collapse isOpen={activeIndex === index} >
                                    <div 
                                       className="collapse show" 
                                       id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion" >
                                        
                                        <div className="card-body">
                                          <div className="learning-header"><span className="f-w-600">Videos</span></div>
                                          <div className="list-group">
                                            {courseList.map((s, i) => {
                                              console.log("courseList",s)
                                              const aaaa = userVideos.find( p => p.video_id === s.id)
                                               const status = aaaa && aaaa.status;
                                              if (id === s.id){
                                                return (                              
                                                    <a className="list-group-item list-group-item-action" style={{cursor: "pointer", color:"blue"}} onClick={ () => clickApply(s)}>
                                                      {s.video_name}
                                                    </a>                                               
                                                    )
                                                } 
                                                else {
                                                  return (                                                                                     
                                                  // <a className="list-group-item list-group-item-action" style={{cursor: "pointer"}} onClick={ () => clickApply(s)}>
                                                  //   {s.video_name}
                                                  // </a>
                                                  <a className={status === "watched" ? "list-group-item list-group-item-action disabled" : "list-group-item list-group-item-action "}   style={{cursor: "pointer"}} onClick={ () => clickApply(s)}>
                                                    {s.video_name}
                                                  </a>                                               
                                                  )
                                                }
                                             
                                              })}
                                          </div>

                                          <div className="checkbox-animated mt-0" >
                                              <div className="media">
                                                  <div className="top-server">
                                                      <div className="learning-header" style={{marginTop:"30px"}}><span className="f-w-600">Assesments</span></div>
                                                      <div className="list-group">
                                                   
                                                      <a className="list-group-item list-group-item-action" style={{cursor: "pointer"}} onClick={ () =>clickAssesments(s.id)}>
                                                        simple assesment
                                                        </a>
                                                        <a className="list-group-item list-group-item-action" style={{cursor: "pointer"}} onClick={ () =>clickImageAssesments(s.id)}>
                                                        image assesment
                                                        </a>
                                                        <a className="list-group-item list-group-item-action" style={{cursor: "pointer"}} onClick={ () =>clickMultiAssesments(s.id)}>
                                                        multiple answers assesment
                                                        </a>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                            <div className="checkbox-animated mt-0" onClick={ () => clickDocuments(s.id)} style={{cursor: "pointer"}}>
                                              <div className="media">
                                                  <div className="top-server">
                                                      <div className="learning-header" style={{marginTop:"10px"}}><span className="f-w-600">Documentation</span></div>
                                                  </div>
                                              </div>
                                            </div>
                                          
                                          
                                        </div>




                                       {/* <div className="card-body">
                                            <div className="checkbox-animated">
                                                <div className="learning-header"><span className="f-w-600">Videos</span></div>
                                                  {courseList.map((s, i) => {
                                                      return (  
                                                          <div className="checkbox-animated mt-0" onClick={ () => clickApply(s)}>
                                                            <div className="media">
                                                                <div className="top-server">
                                                                    <div className="learning-header"><span className="mb-0">{s.video_name}</span></div>
                                                                </div>
                                                            </div>
                                                          </div> 
                                                      )
                                                  })}
                                            </div>
                                        </div> */}

                                    </div>
                                </Collapse>
                                    </div>
                                )
                            })}
 {/* })} */}
                        </div>
                    </div>
                </div>
            </div>


            </div>
            </div>
        </Fragment>
    );
};

export default LearningDeatil;