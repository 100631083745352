import React, { useState ,Fragment ,useEffect } from "react"
import objectAssign from "object-assign";
import RegionSelect from "react-region-select";
import PropTypes from "prop-types";
import {TextField } from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import { Input,Label,CardBody, Col } from "reactstrap"
import Breadcrumb from '../common/breadcrumb';
import { toast } from 'react-toastify';
import { getImageasses, postImageasses,getAllCourses, getCourseVideos, getAllSections ,uploadScript,uploadQuestionImage} from "../../actions/asyncActions";

const UploadAssesment = (props) => {
    const location = props.location
    const section_id = location.state ? location.state.id : 1
    const [allQuestions, setImageasses] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(0);

    const [upload_file, setFile] = useState(null);
    console.log("upload_file",upload_file)
    const [preview, setPreview] = useState();
    console.log("upload_file",upload_file)
    
   const [coords, setCoordinates] = useState([]);
   console.log("coordinates",coords)

    const [courseVideos, setCourseVideos] = useState([]);
    const [values, setValues] = useState([]);
     console.log("values",values)

     const [durations, setDurations] = useState([]);
     console.log("durations",durations)

     const [courses, setCourses] = useState([]);  
     const user = JSON.parse(localStorage.getItem("pm_users"));

     const [imageHeight, setImageHeight] = useState(0);
     const [imageWidth, setImageWidth] = useState(0);
     console.log("height",imageHeight)
     console.log("width",imageWidth)
     const [video_val, setVideo_val] = useState(null)
    //  const Courseid = location.state.Courseid;
     const [course_val, setCourseVal] = useState(null);
     const id = location.state ? location.state.id : 1
     const selectedCourseId = course_val && course_val.id;
     const selectedVideoId = video_val && video_val.id;
     const aaa = course_val && course_val.course_name;
     let bbb = aaa;
     var selectedCourseName = (bbb || '').toUpperCase();

    const [section_val, setSectionVal] = useState(null);
    const selectedSectionId = section_val && section_val.id;
    const [sections, setSections] = useState([]);
    const filterSections = sections.filter(s => s.course_id === selectedCourseId);
    const [regions, setRegions] = useState([]);
    const [objects, setCoords] = useState({});
    console.log("Regions",regions)

    const onInputChange = e => {
        setValues({ ...values, [e.target.name]: e.target.value });
      };


      useEffect( () => {
        getCourseVideos()
        .then(resp => setCourseVideos(resp.data))
      }, []);

      useEffect( () => {
        getAllSections()
        .then(resp => setSections(resp.data))
        }, []);
        
      useEffect( () => {
        getAllCourses()
        .then(resp => setCourses(resp.data))
      }, []);




    useEffect(() => {
        if (!upload_file){
            setPreview(undefined)
            return
        }
        const objectUrl = URL.createObjectURL(upload_file)
        setPreview(objectUrl)
     
        return ()  => URL.revokeObjectURL(objectUrl)
    },[upload_file])



    
    useEffect(async () => {
        await getImageasses()
          .then(resp => {
            const filterQuestions = resp.data.filter(x => x.section_id === section_id)
           setImageasses(filterQuestions)
          })
      }, []);
   console.log("imagesasses", allQuestions);

   



   const handleUpload = (e) => {
   setFile(document.getElementById('file').files[0])
    }



  const handleSubmit = async (event,regionProps) => {
    event.preventDefault();

    const user = JSON.parse(localStorage.getItem("pm_users"));
    
    const formData = new FormData()
    formData.append('file', upload_file)
    
    const uploadData = await uploadScript(formData)
    console.log("uploadData",uploadData)
   
    const imagesasses = {
    
      
      question: values.question,
      image_id: uploadData.data.image_id,
      image:uploadData.data.image_name,
      //image_path:uploadData.data.image_path,
      section_id: selectedSectionId,
      course_id: selectedCourseId,
      video_id:selectedVideoId,
      user_id: user.id,
      marks: values.marks,
      set_timer: values.set_timer,
      coordinates:coords,
      width:imageWidth,
      height:imageHeight
     }
     console.log("uploadimages",imagesasses)
  

     //const result=await uploadQuestionImage(imagesasses)
     
     
     postImageasses(imagesasses)
      .then(resp => {
        getImageasses()
        .then(resp => setImageasses(resp.imagesasses))
        setFile(null)
     //console.log("setFile",setFile)
     setCourseVal({course_id:''})
     setSectionVal({section_id:''})
     setValues({question:''})
     setValues({marks:''})
     setValues({set_timer:''})
     setVideo_val({video_id: ''})
     console.log("setValues",setValues)
     toast.success("Question added successfully", {
      position:
        toast.POSITION.TOP_CENTER, autoClose: 2000
      })
     })
    }


const detectObjects = (area) => {
     setCoords({ savedObject: area });
     let img = document.getElementById("image");
     let xPx = img.clientWidth * area.x * 0.01;
     console.log('imageDim',area)
     let yPx = img.clientHeight * area.y * 0.01;
     let x2Px = img.clientWidth * area.x2 * 0.01;
     let y2Px = img.clientHeight * area.y2 * 0.01;
      let widthPx = img.clientWidth * area.width * 0.01;
      let heightPx = img.clientHeight * area.height * 0.01;
     
     let reverseY = img.clientHeight - yPx;
     setCoordinates(`${xPx.toFixed(2)} , ${yPx.toFixed(2)} , ${(xPx + widthPx).toFixed(2)} , ${(yPx + heightPx).toFixed(2)}`);
     setImageWidth(img.clientWidth);
     setImageHeight(img.clientHeight);
    
    
   }


   const onChange = (regions)=> {
    setRegions(regions)
   
  }

  const changeRegionData = (index, event) => {
    const region = regions[index];
    let color;
    detectObjects(regions[0]);
    color = "rgba(0, 255, 0, 0.5)";

    region.data.regionStyle = {
      background: color
    };
    
    onChange([
      ...regions.slice(0, index),
      objectAssign({}, region, {
        data: objectAssign({}, region.data, { dataType: event.target.value })
      }),
      ...regions.slice(index + 1)
    ]);
  }

 
const regionRenderer = (regionProps)=> {
    if (!regionProps.isChanging) {
      return (
        <div style={{ position: "absolute", right: 0, bottom: "-1.5em" }}>
          <button
            onClick={event => changeRegionData(regionProps.index, event)}
            value={regionProps.data.dataType}
          >
            select region
          </button>
        </div>
      );
    }
  }

const regionStyle = {
    background: "rgba(0, 0, 255, 0.5)",
    zIndex: 99
  };


  


  return (

  <Fragment>
      <Breadcrumb title="Add Image assesment" parent="Form" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-xl-12">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <CardBody>
                  <div className="card-header">
                      <h5>Add Question</h5>
                  </div>
                  <Col md={6}>
                  <div className="mb-3">
                      <Autocomplete
                        freeSolo
                        options={courses}
                        getOptionLabel={option => option.course_name}
                        value={course_val}                      
                        onChange={(event, newValue) => {
                          setCourseVal(newValue);                          
                        }}            
                        renderInput={params => (
                          <TextField {...params} label="Course" margin="normal" variant="outlined" helperText="Please select Course"  />
                        )}
                      />
                    </div>
                    { selectedCourseId ?
                      <div >
                      <div >
                        <div >
                          <Autocomplete 
                            freeSolo
                            options={filterSections}
                            getOptionLabel={option => option.section_name}
                            value={section_val}                      
                            onChange={(event, newValue) => {
                              setSectionVal(newValue);
                            }}            
                            renderInput={params => (
                              <TextField {...params} label="Section" margin="normal" variant="outlined" helperText="Please select Section" fullWidth />
                            )}
                          />
                      </div>
                      </div>
                      </div>
                      : null
                      }
                     
                        <div className="mb-3">
                          <Label htmlFor="formrow-aircraft-Input">Question</Label>
                          <Input
                             type="textarea"
                            className="form-control"
                            value={values.question}
                              onChange={onInputChange}
                            name="question"
                            id="textarea" 
                            rows="3"
                          />
                        </div>
                     
                      <div className="mb-3">
                          <Label htmlFor="formrow-aircraft-Input">Marks</Label>
                          <Input
                             type="number"
                            className="form-control"
                            value={values.marks}
                            onChange={onInputChange}
                            name="marks"
                            id="number" 
                            />
                      </div>
                     
                     
                        <div className="mb-3">
                          <Label htmlFor="formrow-aircraft-Input">set timer</Label>
                          <Input
                             type="number"
                            className="form-control"
                            value={values.set_timer}
                            onChange={onInputChange}
                            name="set_timer"
                            id="number" 
                           
                          />
                        </div>
                      
                      <div className="mb-3">
                      <Label htmlFor="formrow-aircraft-Input">Recommended videos</Label>
                      <Autocomplete
                        freeSolo
                        options={courseVideos}
                        getOptionLabel={option => option.video_name}
                        value={video_val}                      
                        onChange={(event, newValue) => {
                          setVideo_val(newValue);                          
                        }}            
                        renderInput={params => (
                          <TextField {...params}  margin="normal" variant="outlined" helperText="Please select videos"  />
                        )}
                      />
                    </div>
                    <div>
                        <div className="mb-3">
                          <Input type="file" className="form-control" name="file" id="file" onChange={e => {
                                                                handleUpload(e)

                          }} />
                         </div>   
                        {
                           preview != null ? 
                        
                    <div style={{ display: "center", margin: "3% 3% 3% 3%" }}>
                     <div>
       
                        <RegionSelect
                         maxRegions={1}
                         regions={regions}
                         regionStyle={regionStyle}
                         onChange={(regions) =>onChange(regions)}
                         regionRenderer={regionRenderer}
                         style={{ border: "1px solid black" }}
                         constraint={true}
                        >
                       <img id="image" alt="alt" src={preview} />
            
                    </RegionSelect>
          
                    </div>
               </div>
          :null
      }

    <div className="card-footer">
                    <button className="btn btn-primary mr-1"
                    onClick={handleSubmit}
                    >Submit</button>
                   
                  </div>
             </div>
       </Col>
    </CardBody>
  </div>
</div>

</div>
</div>
</div>
</div>
</Fragment>
    )
}
UploadAssesment.propTypes = {
    regions:PropTypes.any
}

export default UploadAssesment;