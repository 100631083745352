import React, { useState , useEffect} from 'react'
import './ImageAsses.css'
import { getImageasses,getCourseVideos,fetchLMSVideo,getUserVideos,editUserVideos } from "../../actions/asyncActions";
import { toast } from 'react-toastify';
import { Modal, ModalHeader,Form,Input,InputGroup, ModalBody, ModalFooter} from "reactstrap"
import { nominalTypeHack } from 'prop-types';


const ImageAssesments = props => {
    const location = props.location
    const section_id = location.state ? location.state.id : 1
    const user = JSON.parse(localStorage.getItem("pm_users"));
    const [sections, setSections] = useState([]);
    const [courseModels, setModels] = useState([]);
    const [Courseid, setCourseid] = useState('');
    const [percent, setPercent] = useState([]);
    const [allQuestions, setImageasses] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [score, setScore] = useState(0);
    const [clicked, setClicked] = useState(false);
    const [showScore, setShowScore] = useState(false);
    console.log("allQuestions",allQuestions)
    const [userVideos, setUserVideos] = useState([]);
    const currentTimer = allQuestions[currentQuestion] ? allQuestions[currentQuestion].set_timer: 60 ;
    const [seconds, setSeconds] = useState(currentTimer)
    const imageName = allQuestions[currentQuestion] ? allQuestions[currentQuestion].image:null;
    console.log("imageName",imageName)
    const imagepath='http://localhost:3005';
    //const imagepath= 'https://learning.nu-pie.com';
    console.log("imagepath",imagepath)
    const filepath = imageName ? `${imagepath}/${imageName}` : null;
    console.log("filepath",filepath)
    const [avg, setAvg] = useState(0);
    const [lmsVideo, setLMSvideo] = useState([]);
    const [wrongId, setWrongId] = useState(null);
    console.log("wrongId",wrongId)
    const[videourl,setVideoUrl] = useState(null);
    const [courseVideos, setCourseVideos] = useState([]); 
    const courseVideosFilter = userVideos.filter(s => s.user_id === user.id &&  s.status === 'Incorrect');
    const foundcourseVideo = courseVideosFilter[0];
    const [subscribemodal, setSubscribemodal] = useState(false)
    const [form, setForm] = useState({});
    const [isActive, setIsActive] = useState(false);

    useEffect( () => {
        getCourseVideos()
        .then(resp => setCourseVideos(resp.data))
      }, []);

      useEffect( () => {
        getUserVideos()
        .then(resp => setUserVideos(resp.data))
      }, []);

      useEffect(() => {
        setTimeout(() => {
          setSubscribemodal(true)
        }, 500)
      }, []) 

      useEffect(async () => {
        await getImageasses()
          .then(resp => {
            const filterQuestions = resp.data.filter(x => x.section_id === section_id)
            const result = filterQuestions;
            setImageasses(filterQuestions)
          })
         }, []);
      console.log("imagesasses", allQuestions);

      
      useEffect(() => {
        if (isActive)  { 
        if (seconds > 0) {
          setTimeout(() => setSeconds(seconds - 1), 1000);
        } else {
         setShowScore(true);
        }
      }
      },[seconds,isActive]);

      const percentage =(avg/allQuestions.length)*100;
      console.log("percentage",percentage)


      const learnMore = (course_id) => {
        const userVideoFilter = userVideos.filter(s => s.user_id === user.id && s.course_id === course_id && s.status === 'Incorrect' );
        setCourseid(course_id);
        console.log("course_id", course_id);
        console.log("userVideoFilter", userVideoFilter);
        var videoObj = userVideoFilter;
        console.log("videoObj", videoObj[0]);
        
        if(videoObj != ''){            

            const id = (videoObj && videoObj[0].video_id) || [];
            const aaaa = courseVideos.find( s => s.id === id );
            console.log("qqqqq",aaaa)
            const Mid = (videoObj && videoObj[0].model_id) || [];
            const sectionId = aaaa && aaaa.section_id;
            const findSection = sections.find(a => a.section_id === sectionId);
            const modelId = findSection && findSection.model_id;
            const findModule = courseModels.find(a => a.model_id === modelId);
            const Mname = findModule && findModule.name;
            
            const VideoName = aaaa && aaaa.video_name;
          
            fetchLMSVideo(VideoName)
            .then(resp => 
            {
                setLMSvideo(resp.data);
                console.log("resp", resp);
                console.log("RespData", resp.data);
                props.history.push(`${process.env.PUBLIC_URL}/courses/course_learning`, { id, Mid, Mname, Courseid:course_id, url: resp.url, reapData:resp.data });
            })

        } else {
            props.history.push(`${process.env.PUBLIC_URL}/courses/course_models`, { id: course_id });
        }
       }



        const handleWrongAnswer =() => {
           toast.warning("Wrong Answer",{
           position:
           toast.POSITION.TOP_CENTER, autoClose: 1000
        })
    

        const data ={
          video_id: allQuestions[currentQuestion].video_id,
          status: 'Incorrect',
          }
          console.log("Data", data);
          editUserVideos(data)
          .then(resp => {
          getUserVideos()
          .then(resp => setUserVideos(resp.data))
           console.log("resp",resp.data)
          
        })
        .catch(err => {
          console.log("Error", err);
        })
  

       setWrongId(allQuestions[currentQuestion].video_id)
          if(currentQuestion < allQuestions.length - 1){
          setCurrentQuestion(currentQuestion + 1)
           }else{
          setShowScore(true);
           }
         }

    
        const handleCorrectAnswer = (area) => {
        if (area) {
             toast.success("Correct Answer",{
                position:
                toast.POSITION.TOP_CENTER, autoClose: 1000
                })
            setScore(score + +allQuestions[currentQuestion].marks);
            if(currentQuestion < allQuestions.length - 1){
                setCurrentQuestion(currentQuestion + 1)
              }else{
                setShowScore(true);
            }
            setAvg( avg + +1);
           }
         setClicked(true);
       if(percentage < 65){
            setPercent("BETTER LUCK NEXT TIME!!!")
        }
        else{
            setPercent("WELL DONE!!!")
        } 
    };
    console.log("avg",avg)
    


   const goBack = () => {
       props.history.push(`${process.env.PUBLIC_URL}/courses/course_models`);
  }


return (
    <div>
    {showScore ? (
        <div>
           <div className="completed">Completed</div>
           <div className="score-section">
               Your Score: {score}
             
           </div>
           <div className="center" style={{textAlign:"center",paddingTop:"130px",color:'orange',fontSize:'20px'}}>
                    {percent}
            </div>
            <div className="header-faq" style={{paddingTop:"180px"}} >
                        <button className="btn btn-pill btn-warning btn-air-warning btn-warning-gradien text-light "  onClick={goBack} >Back</button>
                       
            </div>
            
       </div>

    ) : 
  
    (
         <div >
               
               <div className="answer-section-wrapper" style={{textAlign:'center',backgroundColor:'none'}}>
                     <div className="question-count pull-left answer-section-wrapper" >
                         Question {currentQuestion + 1} / {allQuestions.length}
                     </div>
                     <div className="timer pull-right mt-0 answer-section-wrapper">
                     <div className="time_text answer-section-wrapper pull-right">Time Left</div>
                     <div className="timer_sec answer-section-wrapper pull-right">{seconds}</div>
                     </div>
                     
                     {allQuestions.length > 0 ?
                     <div className="question">
                         
                         {allQuestions[currentQuestion].question}
                     </div>
                     :null
                    }
                 </div>
                 {allQuestions.length > 0 ?
                  
                 <div>
                     
              <center>
                {filepath?
                <div className='answer-button1'>
                <img src={filepath} usemap="#sam" height={allQuestions[currentQuestion].height} width={allQuestions[currentQuestion].width} onClick={handleWrongAnswer}/>
                </div>
                :
                <div className="question">
                          {"Image not found"}
                </div>
                }
                
            </center>
             <map name="sam">
                
                 <area shape="rect" coords={allQuestions[currentQuestion].coordinates} alt=""  onClick={area =>handleCorrectAnswer(area)} />
                 
             </map>
             </div>
   :null
                
}
 </div>
            )           
}
<Modal 
       
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal)
        }}
      >
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <ModalHeader
              toggle={() => {
                setSubscribemodal(!subscribemodal)
              }}
            ></ModalHeader>
          </div>
          <div className="modal-body">
          <Form>
                    
          <div className="answer-button1" style={{textAlign:"center",width:"400px",marginLeft:"35px"}}>
            <h5><p7>Quiz ends in 60 seconds.</p7></h5>
              <h5><p7>Each question consists of 10 marks.</p7></h5>
              <h5><p7>You can click on the image only once.</p7></h5>
              <div  style={{ textAlign:"center",paddingTop:"120px",marginLeft:"25px"}}>
                <h4>click on start to begin</h4>
              </div>
              </div>
             

                  </Form>
                  <div className="text-center ">
                    <button
                      type="button"
                      className="btn btn-primary "
                      onClick={() => 
                        {
                        setIsActive(true)
                        setSubscribemodal(false)
                        }

                      }
                      
                      >
                        START
                    </button>
                  </div>
           
           </div>
        </div>
      </Modal>



             </div>
         
    );
};

export default ImageAssesments;
