import React, { useState ,Fragment, useEffect } from "react"
// import MetaTags from 'react-meta-tags';
import {TextField, Backdrop , CircularProgress, TablePagination} from '@material-ui/core';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap"
//import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link } from "react-router-dom"
//import Select from "react-select"
// const animatedComponents = makeAnimated()
import {Autocomplete} from '@material-ui/lab';
import Breadcrumb from '../common/breadcrumb';
import { getQuestions, postQuestions,getAllCourses, getCourseVideos, getSections, getAllSections, uploadCourseDocs, getCourseDocs, postCourseDocs } from '../../actions/asyncActions';

// import { postQuestions, getQuestions,   } from '../../actions/asyncActions';
import { toast } from 'react-toastify';


const FormAdvanced = (props) => {
    // const [questions, setQuestions] = useState([]);
    const location = props.location
    const [addQuestions, setAddQuestions]= useState([]);
    const [questionsList, setQuestions] = useState([]); 
    const [values, setValues] = useState([]);
     console.log("values",values)

     const [durations, setDurations] = useState([]);
     console.log("durations",durations)

     const [courses, setCourses] = useState([]);  
     const [courseVideos, setCourseVideos] = useState([]);

     useEffect( () => {
      getCourseVideos()
      .then(resp => setCourseVideos(resp.data))
    }, []);


     useEffect( () => {
         getAllCourses()
         .then(resp => setCourses(resp.data))
       }, []);

       const user = JSON.parse(localStorage.getItem("pm_users"));

      //  const Courseid = location.state.Courseid;
      const [video_val, setVideo_val] = useState(null)
       const [course_val, setCourseVal] = useState(null);
       const id = location.state ? location.state.id : 1
       const selectedCourseId = course_val && course_val.id;
       const selectedVideoId = video_val && video_val.id;

       const ccc = video_val && video_val.video_name;
       let ddd = ccc;

       

       const aaa = course_val && course_val.course_name;
       let bbb = aaa;

       var selectedCourseName = (bbb || '').toUpperCase();

      const [section_val, setSectionVal] = useState(null);
      const selectedSectionId = section_val && section_val.id;

      const [docfile, setDocFile] = React.useState(null);

      const [sections, setSections] = useState([]);

      useEffect( () => {
      getAllSections()
      .then(resp => setSections(resp.data))
      }, []);

      const filterSections = sections.filter(s => s.course_id === selectedCourseId);

      const [course_docs, setCourseDocs] = useState([]);

      useEffect( () => {
      getCourseDocs()
      .then(resp => setCourseDocs(resp.data))
      }, []);

      // useEffect( () => {
      //   getSections(Courseid)
      //   .then(resp => setSections(resp.data))
      // }, []);

    //  useEffect(async () => {
    //     await getQuestions()
    //       .then(resp => {
    //         console.log("message", resp)
    //         setQuestions(resp)
    //       })
    //   }, []);

      const onInputChange = e => {
        setValues({ ...values, [e.target.name]: e.target.value });
      };

      // const onAddChange = e => {
      //   setDurations({ ...durations, [e.target.name]: e.target.duration });
      // };

      // const handleAdd = () => {
      //   const timers={
      //     marks:durations.marks,
      //     set_timer:durations.set_timer
      //   }
      // }

      const handleSubmit = () => {
        
        const questions = {
          question: values.question,
          answer_a: values.answer_a,
          answer_b: values.answer_b,
          answer_c: values.answer_c,
          answer_d: values.answer_d,
          correct_answer: values.correct_answer,
          marks: values.marks,
          set_timer: values.set_timer,
          section_id: selectedSectionId,
          course_id: selectedCourseId,
          video_id:selectedVideoId,
          user_id: user.id
          
        }
        console.log("handleSubmit",questions)
     
    
        postQuestions(questions)
          .then(resp => {
            getQuestions()
            .then(resp => setQuestions(resp.questions))
            
            setCourseVal(null)
            setSectionVal(null)
            setDocFile(null)
            //setQuestions({question:'',answer_a:'',answer_b:'',answer_c:'',answer_d:''});
    
            toast.success("Question added successfully", {
              position:
                toast.POSITION.TOP_CENTER, autoClose: 2000
            })
            
          })
    
    
          .catch(err => {
            console.log("Error", err);
          })
    
      }
    return (
        <Fragment>
      <Breadcrumb title="Simple Assesment" parent="Assesment" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-xl-12">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                      <h5>Add Question</h5>
                    {/* <h5>Default Form Layout</h5><span>Using the <a href="#javascript">card</a> component, you can extend the default collapse behavior to create an accordion.</span> */}
                  </div>
                  <CardBody>
                    <Row>
                  <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-aircraft-Input">Set Timer</Label>
                          <Input
                             type="number"
                            className="form-control"
                            value={values.set_timer}
                              onChange={onInputChange}
                            name="timer"
                            id="text" 
                           
                          />
                        </div>
                      
                  <div >
                  <Label htmlFor="formrow-aircraft-Input">Course</Label>
                      <Autocomplete
                        freeSolo
                        options={courses}
                        getOptionLabel={option => option.course_name}
                        value={course_val}                      
                        onChange={(event, newValue) => {
                          setCourseVal(newValue);                          
                        }}            
                        renderInput={params => (
                          <TextField {...params}  margin="normal" variant="outlined" helperText="Please select Course"  />
                        )}
                      />
                    </div>
                    { selectedCourseId ?
                      <div >
                      <div >
                        <div >
                        <Label htmlFor="formrow-aircraft-Input">Section</Label>
                          <Autocomplete 
                            freeSolo
                            options={filterSections}
                            getOptionLabel={option => option.section_name}
                            value={section_val}                      
                            onChange={(event, newValue) => {
                              setSectionVal(newValue);
                            }}            
                            renderInput={params => (
                              <TextField {...params}  margin="normal" variant="outlined" helperText="Please select Section" fullWidth />
                            )}
                          />
                      </div>
                      </div>
                      </div>
                      : null
                      }

                          <div className="mb-3">
                          <Label htmlFor="formrow-aircraft-Input">Marks</Label>
                          <Input
                             type="number"
                            className="form-control"
                            value={values.marks}
                            onChange={onInputChange}
                            name="marks"
                            id="number" 
                            
                          />
                        </div>

                        <div>
                        <Label htmlFor="formrow-aircraft-Input">recommended videos</Label>
                      <Autocomplete
                        freeSolo
                        options={courseVideos}
                        getOptionLabel={option => option.video_name}
                        value={video_val}                      
                        onChange={(event, newValue) => {
                          setVideo_val(newValue);                          
                        }}            
                        renderInput={params => (
                          <TextField {...params} margin="normal" variant="outlined" helperText="Please select videos"  />
                        )}
                      />
                    </div>


                            </Col>
                
                  <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-aircraft-Input">Question</Label>
                          <Input
                             type="textarea"
                            className="form-control"
                            value={values.question}
                              onChange={onInputChange}
                            name="question"
                            id="textarea" 
                            rows="3"
                          />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formrow-aircraft-Input">answer a</Label>
                          <Input
                             type="textarea"
                            className="form-control"
                            value={values.answer_a}
                              onChange={onInputChange}
                            name="answer_a"
                            id="textarea" 
                            rows="2"
                          />
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="formrow-aircraft-Input">answer b</Label>
                          <Input
                             type="textarea"
                            className="form-control"
                            value={values.answer_b}
                            onChange={onInputChange}
                            name="answer_b"
                            id="textarea" 
                            rows="2"
                          />
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="formrow-aircraft-Input">answer c</Label>
                          <Input
                             type="textarea"
                            className="form-control"
                            value={values.answer_c}
                            onChange={onInputChange}
                            name="answer_c"
                            id="textarea" 
                            rows="2"
                          />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formrow-aircraft-Input">answer d</Label>
                          <Input
                             type="textarea"
                            className="form-control"
                            value={values.answer_d}
                            onChange={onInputChange}
                            name="answer_d"
                            id="textarea" 
                            rows="2"
                          />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formrow-aircraft-Input">correct answer</Label>
                          <Input
                             type="textarea"
                            className="form-control"
                            value={values.correct_answer}
                            onChange={onInputChange}
                            name="correct_answer"
                            id="textarea" 
                            rows="2"
                          />
                        </div>
                      </Col>
                      </Row>
                     
                        
          
                   
                   
                      </CardBody>
                 
                  <div className="card-footer">
                    <button className="btn btn-primary mr-1"
                    onClick={handleSubmit}
                    >Submit</button>
                    <button className="btn btn-secondary">Cancel</button>
                  </div>
                </div>
              </div>

</div>
</div>
</div>
</div>
</Fragment>














                               
    )
}

export default FormAdvanced

