import React, { useState , useEffect} from 'react'
import './Assesments2.css'
import {v4 as uuidv4} from "uuid";
// import './questionsList.js'
import QuestionsList from './QuestionsList.js';
import {getAssesTimer, getQuestions,getCourseVideos,getUserVideos,editUserVideos,getSections,postUserVideos,fetchLMSVideo } from '../../actions/asyncActions';
import { Percent } from 'react-feather';
// import useCountDown from 'react-countdown-hook';
import { Player, LoadingSpinner, ControlBar, ReplayControl, ForwardControl, VolumeMenuButton, BigPlayButton } from 'video-react';
import {useVideo} from 'react-use';
import { Modal, ModalHeader,Form,Input,InputGroup, ModalBody, ModalFooter} from "reactstrap"


const Assesments2 = (props) => {
   
    const user = JSON.parse(localStorage.getItem("pm_users"));
    const location = props.location
    const section_id = location.state ? location.state.id : 1
   
    const [modal, setmodal] = useState(false)
    const [subscribemodal, setSubscribemodal] = useState(false)
    const [form, setForm] = useState({});
    console.log("form", form)


    const [courseModels, setModels] = useState([]);
    const [Courseid, setCourseid] = useState('');
    const [courseVideos, setCourseVideos] = useState([]); 
    const [lmsVideo, setLMSvideo] = useState([]);
    const [userVideos, setUserVideos] = useState([]); 
    const { history } = props
    const [sections, setSections] = useState([]);
    const [allQuestions, setQuestions] = useState([]); 

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [score, setScore] = useState(0);
    const [clicked, setClicked] = useState(false);
    const [showScore, setShowScore] = useState(false);
   
    const [avg, setAvg] = useState(0);
    
    const [percent, setPercent] = useState(0);
    const [wrongId, setWrongId] = useState(null);
    console.log("wrongId",wrongId)
    const[videourl,setVideoUrl] = useState(null);

    const [status,setStatus] = useState(null);
    
    console.log("allQuestions",allQuestions)
    
     const totalTimer = (allQuestions && allQuestions.reduce((prev,current) =>  prev + parseFloat(current.set_timer), 0));
    
     const currentTimer = totalTimer ? totalTimer : 60;
    
     const [seconds, setSeconds] = useState(currentTimer)
     console.log("seconds",seconds)


     const [isActive, setIsActive] = useState(false);
     const [counter, setCounter] = useState(0);
   
  
     useEffect(() => {
       
     if (isActive)  {   
      if  (seconds > 0) {
        setTimeout(() => setSeconds(seconds - 1), 1000);
      } else {
         
                  console.log("else part working")
                  setShowScore(true);
              }
            } 
      
    }
    ,[seconds,isActive])
  
  
    
    useEffect( () => {
        getCourseVideos()
        .then(resp => setCourseVideos(resp.data))
      }, []);


      useEffect( () => {
        getUserVideos()
        .then(resp => setUserVideos(resp.data))

      }, []);

   

    useEffect(() => {
      setTimeout(() => {
        setSubscribemodal(true)
      }, 500)
    }, [])  

    useEffect( () => {
        getQuestions()
         .then(resp => {
    //   setQuestions(resp.data);section_id
            const filterQuestions = resp.data.filter(x => x.section_id === section_id)
            const result = filterQuestions;
            let arr = [] 
            result.forEach((n,i) => {
            const qId = n.video_id
            const marks=n.marks
            const set_timer=n.set_timer
            const question=n.question
            const answersList=[
                {answer:n.answer_a, isCorrect: ('a' === n.correct_answer) ? true : false },
                {answer:n.answer_b, isCorrect: ('b' === n.correct_answer) ? true : false },
                {answer:n.answer_c, isCorrect: ('c' === n.correct_answer) ? true : false },
                {answer:n.answer_d, isCorrect: ('d' === n.correct_answer) ? true : false }
            ]
            arr.push({
                question:question,
                answersList:answersList,
                marks:marks,
                qId:qId,
                set_timer:set_timer,  
            })
          })
      
          setQuestions(arr);
          })
      }, []);
   

    const goBack = () => {
       
            props.history.push(`${process.env.PUBLIC_URL}/courses/course_models`);
        
        
     }
       
     const learnMore = (course_id) => {
        const userVideoFilter = userVideos.filter(s => s.user_id === user.id && s.course_id === course_id && s.status === 'Incorrect' );
        setCourseid(course_id);
        console.log("course_id", course_id);
        console.log("userVideoFilter", userVideoFilter);
       
              var videoObj = userVideoFilter;
        console.log("videoObj", videoObj[0]);
        
        if(videoObj != ''){            

            const id = (videoObj && videoObj[0].video_id) || [];
          
            const aaaa = courseVideos.find( s => s.id === id );
            console.log("qqqqq",aaaa)
            const Mid = (videoObj && videoObj[0].model_id) || [];

            const sectionId = aaaa && aaaa.section_id;
            const findSection = sections.find(a => a.section_id === sectionId);
            const modelId = findSection && findSection.model_id;
            const findModule = courseModels.find(a => a.model_id === modelId);
            const Mname = findModule && findModule.name;
            
            const VideoName = aaaa && aaaa.video_name;
            console.log("aaa", aaaa);
             console.log("Mid", Mid);
          
            fetchLMSVideo(VideoName)
            .then(resp => 
            {
                setLMSvideo(resp.data);
                console.log("resp", resp);
                console.log("RespData", resp.data);
                props.history.push(`${process.env.PUBLIC_URL}/courses/course_learning`, { id, Mid, Mname, Courseid:course_id, url: resp.url, reapData:resp.data });
            })

        } else {
            props.history.push(`${process.env.PUBLIC_URL}/courses/course_models`, { id: course_id });
        }
       
    
    
 }
   
 const percentage =(avg/allQuestions.length)*100;
  console.log("percentage",percentage)
  
  

    const handleCorrectAnswer = (isCorrect) => {
        if (isCorrect) {
            setScore(score + +allQuestions[currentQuestion].marks);
            setAvg( avg + +1);
        }
        else {
            const data ={
               video_id: allQuestions[currentQuestion].qId,
                status: 'Incorrect',
            
              }
              console.log("Data", data);
              editUserVideos(data)
              .then(resp => {
                getUserVideos()
                    .then(resp => setUserVideos(resp.data))
                    console.log("resp",resp.data)
                   
                  })
                  .catch(err => {
                    console.log("Error", err);
                  })
             

            setWrongId(allQuestions[currentQuestion].qId)
        }
        setClicked(true);
        if(percentage < 65){
            setPercent("BETTER LUCK NEXT TIME!!!")
        }
        else{
            setPercent("WELL DONE!!!")
        }
    };
    console.log("avg",avg);
    const [payments, setPayment] = useState([]);
    const courseVideosFilter = userVideos.filter(s => s.user_id === user.id &&  s.status === 'Incorrect');
    const foundcourseVideo = courseVideosFilter[0];
    const handlePercentage = (percentage) => {
       
      
    }


   const handleNextQuestion = () => {
        setClicked(false);
        if(currentQuestion < allQuestions.length - 1){
            setCurrentQuestion(currentQuestion + 1)
            
            
        }else{
            setShowScore(true);
            
            getCourseVideos()
            .then(resp => {
                const findVideo = (resp.data).find(r => r.id === parseInt(wrongId))
                console.log("findVideo",resp.data)

                const videoName =findVideo && findVideo.video_name;

            fetchLMSVideo(videoName)
            .then(resp => 
             {
            setVideoUrl(resp.url);
            
             console.log("RespData", resp.url);
              })
           
        })

        }
    };



   
    return (
         <div className="app-wrapper">

             

              
             {showScore ? (
                 <div>
                    
                    <div className="completed">Completed</div>
                    <div className="score-section">
               Your Score: {score}
             
           </div>
                    
                    <div className="container-fluid" style={{textAlign:"center",paddingTop:"130px",color:'orange',fontSize:'20px'}}>
                    {percent}
                   
                    </div>
                       <div className="header-faq" style={{paddingTop:"180px"}} >
                        <button className="btn btn-pill btn-warning btn-air-warning btn-warning-gradien text-light "  onClick={goBack} >Back</button>
                        <span className="btn btn-pill btn-warning btn-air-warning btn-warning-gradien pull-right text-light" onClick ={ ( ) => learnMore(foundcourseVideo.course_id)} >Learn more</span>
                    </div>
                    <div className="col-md-12 xl-100"  >
                   </div>
                 </div>

             ) : (
             <div>

                 <div className="answer-section-wrapper" style={{textAlign:'center',backgroundColor:'none'}}>
                     <div className="question-count pull-left answer-section-wrapper" >
                         Question {currentQuestion + 1} / {allQuestions.length}
                     </div>
                     <div className="timer pull-right mt-0 answer-section-wrapper">
                     <div className="time_text answer-section-wrapper pull-right">Time Left</div>
                     <div className="timer_sec answer-section-wrapper pull-right">{seconds}</div>
                     </div>
                     
                     {allQuestions.length > 0 ?
                     <div className="question">
                         
                         {allQuestions[currentQuestion].question}
                     </div>
                     :null
                    }
                 </div>
                
                  {allQuestions.length > 0 ?
                 <div className="answer-section-wrapper pull-centre ">

                     {allQuestions[currentQuestion].answersList.map((answerOption) => (
                         <li className="answer-list" key={uuidv4()}>
                             <button 
                             disabled={clicked}
                             //{&&}
                             
                             className={`answer-button ${clicked && answerOption.isCorrect ? "correct" : " "} `}
                              onClick={() => handleCorrectAnswer(answerOption.isCorrect)}>
                                 {answerOption.answer}
                             </button>
                         </li>
                     ))}
                 </div>
                  :null
}
             <div>
                 <button className="btn btn-pill btn-secondary btn-air-secondary  btn-secondary-gradien text-light pull-centre"
                  onClick={handleNextQuestion} 
                  disabled={!clicked}>
                     Next
                 </button>
             </div>
             </div>
             )
             
             }
              <Modal className="center" style={{alignItems:"center", height:"350px", width:"800px"}}
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal)
        }}
      >
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <ModalHeader
              toggle={() => {
                setSubscribemodal(!subscribemodal)
              }}
            ></ModalHeader>
          </div>
          <div className="modal-body">
          <Form>
                    
              <div className="input-group">
              

              </div>
              <div className="answer-button1" style={{textAlign:"center",width:"400px",marginLeft:"35px"}}>
            <h5><p7>Quiz ends in 60 seconds.</p7></h5>
              <h5><p7>Each question consists of 5 marks.</p7></h5>
              <h5><p7>choose any one correct answer.</p7></h5>
              <div  style={{ textAlign:"center",paddingTop:"120px",marginLeft:"25px"}}>
                <h4>click on start to begin</h4>
              </div>
              </div>

                  </Form>
                  <div className="text-center mt-4">
                    <button
                      type="button"
                      className="btn btn-primary "
                      onClick={() => 
                        {setIsActive(true)
                        setSubscribemodal(false)
                        
                        }

                      }
                      
                      >
                        START
                    </button>
                  </div>
           
           </div>
        </div>
      </Modal>
         </div>
    );
};

export default Assesments2;
