import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import seven from '../../assets/images/user/7.jpg';
import { getUser, getAllUser, editUsers, postUsers } from '../../actions/asyncActions';

export default function UserEdit() {
//const UserEdit = () => {
  const user = JSON.parse(localStorage.getItem("pm_users"));
  const user_id = user.id;
  // const lab_id = user.lab_id;
  const [user_list, setUsers] = useState([]);
  const [values, setValues] = useState({
    firstname: '',
    logo: '',
    email: '',
    phone: '',
    gstin: '',
    currency: '',
    tax: '',
    discount: '',
    header: '',
    city: '',
    state: '',
    footer: '',
    lab_id: '' 
  });

  useEffect( () => {
    getUser(user_id)
    .then(resp => { setValues(resp.data) } )
  }, []);

  useEffect( () => {
    getAllUser()
    .then(resp => setUsers(resp.data))
    .catch(() => this.setState({ hasErrors: true }))
  }, []);

  console.log("values", values)

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async (event) => {
    const user = JSON.parse(localStorage.getItem("pm_users"));
        const edit_user = {
          firstname: values.firstname,
          lastname: values.lastname,
          username: values.username,
          password: values.password,
          email: values.email,
          phone: values.phone,
        }
        console.log("edit_user",edit_user)
        editUsers(edit_user)
        .then(resp => {
            getUser(user.id)
            .then(resp => setUsers(resp.data))
          })
          .catch(err => {
            console.log("Error", err);
          })
      }

    return (
        <Fragment>
            <Breadcrumb parent="User" title="Edit Profile" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">My Profile</h4>
                                    <div className="card-options">
                                        <a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <form>
                                        <div className="row mb-2">
                                            <div className="col-auto"><img className="img-70 rounded-circle" alt="" src={seven} /></div>
                                            <div className="col">
                                                <h3 className="mb-1">{user.firstname} {user.lastname}</h3>
                                                <p className="mb-4">{user.role}</p>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">User Name</label>
                                            <input className="form-control" type="text" placeholder="UserName" name="username" value={values.username} onChange={handleChange} />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Password</label>
                                            <input className="form-control" type="password" value={values.username} readOnly />
                                        </div>
                                        <div className="form-footer">
                                            <button className="btn btn-primary btn-block" onClick={handleSubmit}>Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <form className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">Edit Profile</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">First Name</label>
                                                <input className="form-control" type="text" placeholder="First Name" name="firstname" value={values.firstname} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Last Name</label>
                                                <input className="form-control" type="text" placeholder="Last Name" name="lastname" value={values.lastname} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">UserName</label>
                                                <input className="form-control" type="text" placeholder="UserName" name="username" value={values.username} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Password</label>
                                                <input className="form-control" type="password" placeholder="Password" name="password" value={values.password} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Email address</label>
                                                <input className="form-control" type="email" placeholder="Email" name="email" value={values.email} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Phone</label>
                                                <input className="form-control" type="number" placeholder="Phone" name="phone" value={values.phone} onChange={handleChange} />
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn btn-primary" type="submit" onClick={handleSubmit} >Update Profile</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">Add projects And Upload</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table card-table table-vcenter text-nowrap">
                                        <thead>
                                            <tr>
                                                <th>Project Name</th>
                                                <th>Date</th>
                                                <th>Status</th>
                                                <th>Price</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><a className="text-inherit" href="javascript">Untrammelled prevents </a></td>
                                                <td>28 May 2018</td>
                                                <td><span className="status-icon bg-success"></span> Completed</td>
                                                <td>$56,908</td>
                                                <td className="text-right">
                                                    <button className="btn btn-primary btn-sm" href="javascript">
                                                        <i className="fa fa-pencil"></i> Edit
                                                    </button>
                                                    <button className="btn btn-transparent btn-sm" href="javascript">
                                                        <i className="fa fa-link"></i> Update
                                                    </button>
                                                    <button className="btn btn-danger btn-sm" href="javascript">
                                                        <i className="fa fa-trash"></i> Delete
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a className="text-inherit" href="javascript">Untrammelled prevents</a></td>
                                                <td>12 June 2018</td>
                                                <td><span className="status-icon bg-danger"></span> On going</td>
                                                <td>$45,087</td>
                                                <td className="text-right">
                                                    <button className="btn btn-primary btn-sm" href="javascript">
                                                        <i className="fa fa-pencil"></i> Edit
                                                    </button>
                                                    <button className="btn btn-transparent btn-sm" href="javascript">
                                                        <i className="fa fa-link"></i> Update
                                                    </button>
                                                    <button className="btn btn-danger btn-sm" href="javascript">
                                                        <i className="fa fa-trash"></i> Delete
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a className="text-inherit" href="javascript">Untrammelled prevents</a></td>
                                                <td>12 July 2018</td>
                                                <td><span className="status-icon bg-warning"></span> Pending</td>
                                                <td>$60,123</td>
                                                <td className="text-right">
                                                    <button className="btn btn-primary btn-sm" href="javascript">
                                                        <i className="fa fa-pencil"></i> Edit
                                                    </button>
                                                    <button className="btn btn-transparent btn-sm" href="javascript">
                                                        <i className="fa fa-link"></i> Update
                                                    </button>
                                                    <button className="btn btn-danger btn-sm" href="javascript">
                                                        <i className="fa fa-trash"></i> Delete
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a className="text-inherit" href="javascript">Untrammelled prevents</a></td>
                                                <td>14 June 2018</td>
                                                <td><span className="status-icon bg-warning"></span> Pending</td>
                                                <td>$70,435</td>
                                                <td className="text-right">
                                                    <button className="btn btn-primary btn-sm" href="javascript">
                                                        <i className="fa fa-pencil"></i> Edit
                                                    </button>
                                                    <button className="btn btn-transparent btn-sm" href="javascript">
                                                        <i className="fa fa-link"></i> Update
                                                    </button>
                                                    <button className="btn btn-danger btn-sm" href="javascript">
                                                        <i className="fa fa-trash"></i> Delete
                                                    </button>
                                                </td>
                                                
                                            </tr>
                                            <tr>
                                                <td><a className="text-inherit" href="javascript">Untrammelled prevents</a></td>
                                                <td>25 June 2018</td>
                                                <td><span className="status-icon bg-success"></span> Completed</td>
                                                <td>$15,987</td>
                                                <td className="text-right">
                                                    <button className="btn btn-primary btn-sm" href="javascript">
                                                        <i className="fa fa-pencil"></i> Edit
                                                    </button>
                                                    <button className="btn btn-transparent btn-sm" href="javascript">
                                                        <i className="fa fa-link"></i> Update
                                                    </button>
                                                    <button className="btn btn-danger btn-sm" href="javascript">
                                                        <i className="fa fa-trash"></i> Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

//export default UserEdit;