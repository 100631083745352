import React, { useState ,Fragment, useEffect } from "react"
// import MetaTags from 'react-meta-tags';
import {TextField, Backdrop , CircularProgress, TablePagination} from '@material-ui/core';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap"
//import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link } from "react-router-dom"
//import Select from "react-select"
// const animatedComponents = makeAnimated()
import {Autocomplete} from '@material-ui/lab';
import Breadcrumb from '../common/breadcrumb';
//import { getQuestions, postQuestions,getAllCourses,getSections, getAllSections, uploadCourseDocs, getCourseDocs, postCourseDocs } from '../../actions/asyncActions';
// import { postQuestions, getQuestions,   } from '../../actions/asyncActions';
import { toast } from 'react-toastify';
import { getImageasses, postImageasses,getAllCourses, getCourseVideos, getAllSections ,uploadScript,uploadQuestionImage} from "../../actions/asyncActions";


const UploadAssesment = (props) => {
    // const [questions, setQuestions] = useState([]);
    const location = props.location
    //const [addQuestions, setAddQuestions]= useState([]);
    const [imagesassesList, setImageasses] = useState([]);
    const [courseVideos, setCourseVideos] = useState([]);
    const [values, setValues] = useState([]);
     console.log("values",values)

     const [durations, setDurations] = useState([]);
     console.log("durations",durations)

     const [courses, setCourses] = useState([]);  

     //const logopath = imgassesments && imgassesments.upload_image;

       const user = JSON.parse(localStorage.getItem("pm_users"));



       const [video_val, setVideo_val] = useState(null)
      //  const Courseid = location.state.Courseid;
       const [course_val, setCourseVal] = useState(null);
       const id = location.state ? location.state.id : 1
       const selectedCourseId = course_val && course_val.id;
       const selectedVideoId = video_val && video_val.id;
       const aaa = course_val && course_val.course_name;
       let bbb = aaa;
       var selectedCourseName = (bbb || '').toUpperCase();

      const [section_val, setSectionVal] = useState(null);
      const selectedSectionId = section_val && section_val.id;
      const [sections, setSections] = useState([]);
      const filterSections = sections.filter(s => s.course_id === selectedCourseId);

     
      const [upload_file, setFile] = useState(null);
      console.log("file", upload_file)



      
      useEffect( () => {
        getCourseVideos()
        .then(resp => setCourseVideos(resp.data))
      }, []);

      useEffect( () => {
        getAllSections()
        .then(resp => setSections(resp.data))
        }, []);
        
      useEffect( () => {
        getAllCourses()
        .then(resp => setCourses(resp.data))
      }, []);

const handleUpload = (e) => {
    //console.log(e.target.files)
    // setFile(e.target.files[0])
    setFile(document.getElementById('file').files[0])
  }

      const previewImage = (e) =>{
        var file = document.getElementById("file").files;
        
        if (file.length>0)
        {
          var fileReader = new FileReader();
          fileReader.onload = function(event) {
            document.getElementById("preview").setAttribute("src", event.target.result);
          };
          fileReader.readAsDataURL(file[0])
        }
      }
      



      const onInputChange = e => {
        setValues({ ...values, [e.target.name]: e.target.value });
      };

      
      const handleSubmit = async (e) => {
        e.preventDefault();
        const user = JSON.parse(localStorage.getItem("pm_users"));
        
        const formData = new FormData()
        formData.append('file', upload_file)
        
        const uploadData = await uploadScript(formData)
        
        console.log("uploadData",uploadData)
        const imagesasses = {
          //image:formData,
          
          question: values.question,
          image_id: uploadData.data.image_id,
          image_name:uploadData.data.image_name,
          image_path:uploadData.data.image_path,
          section_id: selectedSectionId,
          course_id: selectedCourseId,
          video_id:selectedVideoId,
          user_id: user.id,
          marks: values.marks,
          set_timer: values.set_timer
         }
         const result=await uploadQuestionImage(imagesasses)
         
         
        //  postImageasses(imagesasses)
        //   .then(resp => {
        //     getImageasses()
        //     .then(resp => setImageasses(resp.imagesasses))

            setFile(null)
         //console.log("setFile",setFile)
         setCourseVal({course_id:''})
         setSectionVal({section_id:''})
         setValues({question:''})
         setValues({marks:''})
         setValues({set_timer:''})
         //console.log("setValues",setValues)
        // const resFile = uploadData.filename
        // const fileType = uploadData.mimetype
        toast.success("Question added successfully", {
          position:
            toast.POSITION.TOP_CENTER, autoClose: 2000
          })
        // })
         // .catch(err => {
        //   console.log("Error", err);
        // })
       
      }
      

      const handleSubmit2 =async (e) => {
        e.preventDefault();
        // const formData = new FormData()
        // formData.append('file', upload_file)
        
        // const uploadData = await uploadScript(formData)
        // console.log("uploadData",uploadData)
        // //const logopath = uploadData.data.filename;
        // const resFile = uploadData.filename
        // const fileType = uploadData.mimetype

         const imagesasses = {
          question: values.question
          // image: resFile,
          // type: fileType
        
          
         }
        console.log("handleSubmit",imagesasses)
     
    
        // postImageasses(imagesasses)
        // .then(resp => {
        //   getImageasses()
        //   .then(resp => setQuestions(resp.imagesasses))
        //   //setValues({category:'',description:''});
  
        //   toast.success("Question added successfully", {
        //     position:
        //       toast.POSITION.TOP_CENTER, autoClose: 2000
        //   })
          
        // })
          .catch(err => {
            console.log("Error", err);
          })
    
      }
    return (
        <Fragment>
      <Breadcrumb title="Form Default" parent="Form" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-xl-12">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                      <h5>Add Question</h5>
                    {/* <h5>Default Form Layout</h5><span>Using the <a href="#javascript">card</a> component, you can extend the default collapse behavior to create an accordion.</span> */}
                  </div>
                  <CardBody>
                  <div className="col-md-6">
                      <Autocomplete
                        freeSolo
                        options={courses}
                        getOptionLabel={option => option.course_name}
                        value={course_val}                      
                        onChange={(event, newValue) => {
                          setCourseVal(newValue);                          
                        }}            
                        renderInput={params => (
                          <TextField {...params} label="Course" margin="normal" variant="outlined" helperText="Please select Course"  />
                        )}
                      />
                    </div>
                    { selectedCourseId ?
                      <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6 ">
                          <Autocomplete 
                            freeSolo
                            options={filterSections}
                            getOptionLabel={option => option.section_name}
                            value={section_val}                      
                            onChange={(event, newValue) => {
                              setSectionVal(newValue);
                            }}            
                            renderInput={params => (
                              <TextField {...params} label="Section" margin="normal" variant="outlined" helperText="Please select Section" fullWidth />
                            )}
                          />
                      </div>
                      </div>
                      </div>
                      : null
                      }
                     <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-aircraft-Input">Question</Label>
                          <Input
                             type="textarea"
                            className="form-control"
                            value={values.question}
                              onChange={onInputChange}
                            name="question"
                            id="textarea" 
                            rows="3"
                          />
                        </div>
                      </Col>
                      
                    
                      <Col md={6}>
                        <div className="input-group mb-3">
                          <Input type="file" className="form-control" name="file" id="file" onChange={e => {
                                                                handleUpload(e)
                                                                 previewImage(e)
                                                            }} />
                          {/* <img src={previewImage}/> */}
                          <Label className="input-group-text" htmlFor="inputGroupFile02">Upload</Label>
                        </div>
                        <div className="mb-3 mt-3" style={{alignItems:"center"}}>
                          <img id="preview"
                         
                         
                          className="avatar-sm-5 rounded bg-light"
                          >
                          </img>
                        </div>
                      </Col>
                    
                   
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-aircraft-Input">Marks</Label>
                          <Input
                             type="number"
                            className="form-control"
                            value={values.marks}
                            onChange={onInputChange}
                            name="marks"
                            id="number" 
                            
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-aircraft-Input">set timer</Label>
                          <Input
                             type="number"
                            className="form-control"
                            value={values.set_timer}
                            onChange={onInputChange}
                            name="set_timer"
                            id="number" 
                           
                          />
                        </div>
                      </Col>
                      <div className="col-md-6">
                      <Autocomplete
                        freeSolo
                        options={courseVideos}
                        getOptionLabel={option => option.video_name}
                        value={video_val}                      
                        onChange={(event, newValue) => {
                          setVideo_val(newValue);                          
                        }}            
                        renderInput={params => (
                          <TextField {...params} label="recommend video" margin="normal" variant="outlined" helperText="Please select videos"  />
                        )}
                      />
                    </div>
                      


                </CardBody>
                 
                  <div className="card-footer">
                    <button className="btn btn-primary mr-1"
                    onClick={handleSubmit}
                    >Submit</button>
                    <button className="btn btn-secondary">Cancel</button>
                  </div>
                </div>
              </div>

</div>
</div>
</div>
</div>
</Fragment>

  )
}

export default UploadAssesment;

