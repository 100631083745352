import React, { Fragment, useState, useEffect } from 'react';
import { Navbar, Form, Nav, FormControl, NavDropdown } from 'react-bootstrap';
import { getAllCourses, getCategory } from '../actions/asyncActions';
import nupielogo from './nupie.png';
import SearchHeader from '../components/common/header-component/searchHeader';
import app from "../data/base";
import { Link } from 'react-router-dom';
import { User, Mail, Lock, Settings, LogOut } from 'react-feather';
import { signOut } from "../actions/UserContext";
//import { withRouter } from "react-router";
import Header from './header';
import { AlignLeft, Maximize, Bell, MessageCircle, MoreHorizontal } from 'react-feather';
import UserLogo from '../assets/images/user/user.png';
 
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap';
import './Header.css'
const Home = (props) => {
  const user = JSON.parse(localStorage.getItem("pm_users"));
  console.log("user", user);
  //console.log("userId", user.id);

  //const imagePath = `http://localhost:9000`;
  //const pathpath = 'http://172.105.62.208:9004'
  //const pathpath = 'http://172.105.38.239:9004'
  //const invoicePath = `${pathpath}${data.inv_file}`


  const [categories, setCategory] = useState([]);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    getCategory()
      .then(resp => setCategory(resp.data))
  }, []);

  useEffect(() => {
    getAllCourses()
      .then(resp => setCourses(resp.data))
  }, []);

  console.log("categories", categories);

  const clickApply = () => {
    props.history.push(`${process.env.PUBLIC_URL}/pages/login`);
  }

  const clickApplySignup = () => {
    props.history.push(`${process.env.PUBLIC_URL}/pages/signup`);
  }

  const clickApplyLogo = () => {
    props.history.push(`${process.env.PUBLIC_URL}/pages/home`);
  }

  const clickApplyCourses = (n) => {
    //const id = n.id;
    props.history.push(`${process.env.PUBLIC_URL}/courses/course_list`, { id: n.id });
  }

  const clickAllCourses = () => {
    props.history.push(`${process.env.PUBLIC_URL}/courses/all_course_list`);
  }

  const clickApplyAllCourses = (n) => {
    //const id = n.id;
    props.history.push(`${process.env.PUBLIC_URL}/courses/course_list`, { id: n.id });
  }

  const logOut = () => {
    localStorage.removeItem('profileURL')
    app.auth().signOut()
    props.history.push(`${process.env.PUBLIC_URL}/pages/home`);
  }

  const clickMyCourses = () => {
    props.history.push(`${process.env.PUBLIC_URL}/courses/mycourses`);
  }

  const logOut2 = (history) => {
    console.log("history", history)
    localStorage.clear("id_token");
    //localStorage.removeItem("id_token");
    props.history.push(`${process.env.PUBLIC_URL}/pages/home`);
  }

  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="container-fluid p-0 m-0">

          <Navbar  className="shadow-lg p-25 shadow-showcase navbar-sticky " bg="dark" variant="dark" expand="lg" sticky="top">
            <Navbar.Brand onClick={() => clickApplyLogo()}>
              <img
                src={nupielogo}
                width="30"
                height="30"
                style={{ cursor: "pointer" }}
                className="d-inline-block align-top"
              />{' '} LMS Nu-pie
                    </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto navbar-default navbar-nav" >
                <Nav.Link className="nav-links" onClick={() => clickAllCourses()}><b>All Courses</b></Nav.Link>
                {user ?
                  <Nav.Link className="nav-links" onClick={() => clickMyCourses()}><b>My Courses</b></Nav.Link>
                  : null
                }
                {/*<NavDropdown title="Our Blog" id="collasible-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                          </NavDropdown> */}
              </Nav>

              {user ?
                <Nav>
                  {/* <button className="btn btn-square btn-outline-primary btn-sm" type="button" 
                            onClick={() => logOut2(props.history)} >
                              <i className= "icon-power-off"></i> LogOut
                          </button>
                          <div className="avatar img-40 rounded-circle" style={{color:'black'}}>
                            <i className="icofont icofont-student-alt"></i>
                            <i className="icofont icofont-ui-user"></i>
                            <i className="fa fa-user"></i>
                            {user.firstname}
                          </div> 

                          <div className="avatar" style={{color:'black'}}>
                            <i className="icofont icofont-student-alt"></i> {user.firstname}
                          </div>
                           */}

                  <UncontrolledDropdown setActiveFromChild>
                    <DropdownToggle tag="a" caret>
                      <img
                        className="img-30 rounded-circle"
                        src={UserLogo}
                        style={{ cursor: "pointer" }}
                        alt="#"
                      />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem active>{user.firstname}</DropdownItem>
                      <DropdownItem onClick={() => clickMyCourses()}>
                        My Courses
                                </DropdownItem>
                      <DropdownItem onClick={() => logOut2(props.history)}>
                        <i className="icon-power-off"></i> LogOut
                                </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>

                </Nav>
                :
                <Nav style={{paddingRight:'10px'}}>
                  <button className="btn btn-clr btn-sm" type="button" onClick={() => clickApply()}>
                   <b> Login
                   </b> </button>
                  <button className="btn btn-clr btn-sm" type="button" onClick={() => clickApplySignup()}><b>
                    Enroll Today
                     </b></button>
                </Nav>
              }
            </Navbar.Collapse>
          </Navbar>
          
          <div className="comingsoon comingsoon-bgimg">

            <div className="comingsoon-inner text-center" >
              
            {/* <div className="col-xl-4 col-sm-4">
                  <div className="card">
                      <div className="card-body shadow-lg p-25 shadow-showcase text-center ">
                      <div className="card-body">
                        <h5 className="txt-primary ">Getting started is easy!!</h5>
                        <b><p1 className=" txt-primary">what do you want to learn today?</p1></b>
                          <div className="col p-35">
                              <SearchHeader />
                          </div>
                      </div>
                      </div>
                  </div>
              </div> */}
              
              {/* <h1 className="display-1 txt-primary">Always be growing.</h1> */}
              <h5 className="f-w-300 text-dark" >
                Structured, online training programs in software development and design for career-minded adults with busy lives.
                        </h5>
              <div>
                <button className="btn btn-square btn-warning btn-sm" type="button" onClick={() => clickAllCourses()}>
                  Explore Our Courses
                          </button>
              </div>
              {/* <div> */}
              {/* <div className="row ">
                <div className="col-sm-4 ">
                  <div className="shadow-lg p-25 shadow-showcase text-center ">
                    <div className="m-0 f-18 display-1 txt-primary">Get started</div>
                  </div>
                </div> */}
                {/* <div className="col-sm-4">
                  <div className="shadow-lg p-25 shadow-showcase text-center ">
                    <h8 className="m-0 f-18 display-1 txt-primary" >Open-source learning</h8>
                  </div>
                </div> */}
                {/* <div className="col-sm-4">
                  <div className="shadow-lg p-25 shadow-showcase text-center ">
                    <h8 className="m-0 f-18 display-1 txt-primary"></h8>
                  </div>
                </div> */}
                {/* <div className="col-sm-3">
                  <div className="shadow-lg p-25 shadow-showcase text-center ">
                    <h5 className="m-0 f-18 display-1 txt-primary"></h5>
                  </div>
                </div> */}
              {/* </div> */}
              {/* </div> */}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5 align='center'>Trending</h5>
                </div>
                <div className="card-body">
                <div id="aniimated-thumbnials10" className="row gallery my-gallery">
                  {categories.map((n, i) => {
                    const found = courses.filter(s => s.cat_id === n.id);
                    {/* const imagePath = `http://localhost:9000/${n.src}`; */ }
                    const imagePath = `http://172.105.41.149:9000${n.src}`;
                    console.log("imagePath", imagePath);
                    return (
                      // <div id="aniimated-thumbnials10" className="row gallery my-gallery">
                      <figure itemProp="associatedMedia" className="col-md-2 col-4 img-hover hover-1"  
                        onClick={() => clickApplyCourses(n)} style={{ cursor: "pointer" }}>
                        <img
                          src={imagePath}
                          alt="Gallery"
                          className="img-thumbnail"
                         
                          // value={n.name}
                          // {found.length} 
                        />
                        <div className="caption" style={{paddingTop:'20px'}}>
                          <h6><b>{n.name}</b></h6>
                          <p>Over {found.length} Courses</p>
                        </div>
                      </figure>
                    //  </div>

                    )
                  })
                  }
                </div>
                </div>
              </div>
            </div>
          </div>

          <footer class="page-footer font-small mdb-color pt-4" style={{ backgroundColor: 'black', color: 'white' }}>
            <div class="container-fluid text-center text-md-left">

              <div class="row">

                <div class="col-md-3 mt-md-0 mt-3">
                  <h5 class="text-uppercase font-weight-bold">Nu-pie</h5>
                  <p>About us</p>
                  <p>Contact us</p>
                  <p>Report issues</p>
                  <p>Browse issues</p>
                </div>

                <div class="col-md-3 mt-md-0 mt-3">
                  <h5 class="text-uppercase font-weight-bold">Careers</h5>
                  <p>Blog</p>
                  <p>Help and Support</p>
                  <p>Affiliate</p>
                </div>

                <hr class="clearfix w-100 d-md-none pb-3" />

                <div class="col-md-3 mb-md-0 mb-3">
                  <h5 class="text-uppercase font-weight-bold">Terms</h5>
                  <p>Privacy policy</p>
                  <p>Sitemap</p>
                </div>

                <div class="col-md-3 mb-md-0 mb-3">
                  <h5 class="text-uppercase font-weight-bold">Address</h5>
                  <p>Innovation Center, KHB Colony, 5th Block, Koramangala, Bengaluru, Karnataka 560095</p>
                  
                </div>

                

              </div>

            </div>

            <div class="footer-copyright text-center py-3">Copyright 2020 ©
                    <a href="http://nu-pie.com/">Nu-pie</a> All rights reserved.
                  </div>
          </footer>

        </div>
      </div>
    </Fragment>
  );
};

export default Home;