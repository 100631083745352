import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { TablePagination } from '@material-ui/core';
import { getAllCourses, postCourses, editCourses, updateCourses, deleteCourses } from '../../actions/asyncActions';

const MyUser = () => {
    const user = JSON.parse(localStorage.getItem("pm_users"));
    const [course, setCourse] = useState([]);
    const initialFormState = { id: '', course_name:'', short_note:'', description:'', src:'', price:'', badgeType:'',
      badgeValue:'', area:'', country:'', cat_id:''}
    const [ cu_course, setCurrentCourse ] = useState(initialFormState)
    const [modal, setModal] = useState();
    const [addmodal, setAddModal] = useState();
    const [values, setValues] = useState({});

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    useEffect( () => {
     getAllCourses()
     .then(resp => {
      setCourse(resp.data);
     })
    }, []);

    const handleChange = event => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
    };

    const handleInputChange = event => {
        const { name, value } = event.target
        setCurrentCourse({ ...cu_course, [name]: value })
    }

    const addRow = () => {
     setAddModal(!addmodal)
    }

    const editRow = user => {
     setModal(!modal)
     setCurrentCourse({ id: user.id, course_name: user.course_name, short_note: user.short_note, description: user.description, 
       price:user.price, badgeType:user.badgeType, badgeValue:user.badgeValue, area:user.area, country:user.country, cat_id:user.cat_id })
    }

    const handleSubmit = () => {
        const userss = JSON.parse(localStorage.getItem("pm_users"));
        const adduser = {
          course_name: values.course_name,
          short_note: values.short_note,
          description: values.description,
          price: values.price,
          badgeType: 'primary',
          badgeValue: 'New',
          area: values.area,
          country: values.country,
          cat_id: values.cat_id
        }
        console.log("adduser", adduser);
        postCourses(adduser)
        .then(resp => {
            getAllCourses()
            .then(resp => setCourse(resp.data))
            setAddModal(!addmodal)
          })
          .catch(err => {
            console.log("Error", err);
        })
   }

  let userObj = {};
  userObj = (course || []).sort((a,b) => {
    return b.id-a.id;
  });
  console.log("userObj", userObj[0]);

    const handleUpdate = (id, data) => {
       // const user = JSON.parse(localStorage.getItem("pm_users"));
        const bbb = {
          id: id,
          course_name: data.course_name,
          short_note: data.short_note,
          description: data.description,
          price: data.price,
          area: data.area,
          country: data.country,
          cat_id: data.cat_id
        }
        editCourses(bbb)
        .then(resp => {
              getAllCourses()
              .then(resp => setCourse(resp.data))
            })
            .catch(err => {
              console.log("Error", err);
            })
        setModal(!modal)
    }

    const deleteRow = (n) => {
      console.log("user_id", n.id)
      const aaa = {
        id: n.id
      }
      deleteCourses(aaa)
      .then(resp => {
        getAllCourses()
        .then(resp => {
          setCourse(resp.data);
        });
      })
      .catch(err => {
        console.log("Error", err);
      })
    };

        return (
            <Fragment>
            <Breadcrumb title="All Courses" parent="Courses" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-sm table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th scope="col">
                                                     <input className="checkbox_animated" type="checkbox" />
                                                    </th>
                                                    <th scope="col">#</th>
                                                    <th scope="col">CourseName</th>
                                                    <th scope="col">Price</th>
                                                    <th scope="col">badgeType</th>
                                                    <th scope="col">short_note</th>
                                                    <th scope="col">Category</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {course.filter(s => s.role !== 'superadmin')
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((n, i)=> {
                                                  return (
                                                    <tr>
                                                     <td> <input className="checkbox_animated" type="checkbox" /> </td>
                                                     <th scope="row">{i+1}</th>
                                                     <td>{n.course_name}</td>
                                                     <td>₹{n.price}</td>
                                                     <td>{n.badgeType}</td>
                                                     <td>{n.short_note}</td>
                                                     <td>{n.cat_id}</td>
                                                     <td>
                                                     {/* <button className="btn btn-info btn-xs" type="button" onClick={() => editRow(n)}>
                                                       <i className="icofont icofont-edit"></i>
                                                      </button> */}
                                                      <i className="fa fa-edit" onClick={() => editRow(n)} style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}
                                                      ></i>
                                                      <i className="fa fa-trash" onClick={() => deleteRow(n)} style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}
                                                      ></i>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="card" >
                            <div className="card-block row">
                                <div className="col-sm-6 col-lg-6 col-xl-6">
                                 <button className="btn btn-pill btn-secondary  text-light" type="button" onClick={() => addRow()}
                                   >
                                   Add Course
                                  </button>
                                </div>
                                <div className="col-sm-6 col-lg-6 col-xl-6">
                                  <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={course.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                   />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* < !-- Using Form Modal for Add Users --> */}
            <Modal isOpen={addmodal} toggle={addRow}>
                <ModalHeader toggle={addRow}>Add Course</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Course Name:</label>
                              <input className="form-control" name="course_name" type="text" placeholder="Course name" 
                               value={values.course_name} onChange={handleChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Price:</label>
                              <input className="form-control" name="price" type="number" placeholder="Price" 
                               value={values.price} onChange={handleChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Area:</label>
                              <input className="form-control" name="area" type="text" placeholder="Area" 
                               value={values.area} onChange={handleChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Country:</label>
                              <input className="form-control" name="country" type="text" placeholder="Country" 
                               value={values.country} onChange={handleChange}/>
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Short Note:</label>
                              <input className="form-control" name="short_note" type="text" placeholder="Short Note" 
                               value={values.short_note} onChange={handleChange}/>
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Description:</label>
                              <input className="form-control" name="description" type="text" placeholder="Description" 
                               value={values.description} onChange={handleChange}/>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit} >Submit</Button>
                    <Button color="secondary" onClick={addRow}>Cancel</Button>
                </ModalFooter>
            </Modal>

            {/* < !-- Using Form Modal for Edit Users --> */}
            <Modal isOpen={modal} toggle={editRow}>
                <ModalHeader toggle={editRow}>Edit Course</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Course Name:</label>
                              <input className="form-control" name="course_name" type="text" placeholder="Course Name" 
                               value={cu_course.course_name} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Price:</label>
                              <input className="form-control" name="price" type="number" placeholder="Price" 
                               value={cu_course.price} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Area:</label>
                              <input className="form-control" name="area" type="text" placeholder="Area" 
                               value={cu_course.area} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Country:</label>
                              <input className="form-control" name="country" type="text" placeholder="Country" 
                               value={cu_course.country} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Short Note:</label>
                              <input className="form-control" name="short_note" type="text" placeholder="Short Note" 
                               value={cu_course.short_note} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Description:</label>
                              <input className="form-control" name="description" type="text" placeholder="Description" 
                               value={cu_course.description} onChange={handleInputChange}/>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={ () => handleUpdate(cu_course.id, cu_course)} >Save Changes</Button>
                    <Button color="secondary" onClick={editRow}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
        );
}

export default MyUser;