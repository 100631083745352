import React, { useState , useEffect} from 'react'
//import './Assesments2.css'
import {v4 as uuidv4} from "uuid";
import {  Label } from "reactstrap"
// import './questionsList.js'
//import QuestionsList from './QuestionsList.js';
import { getmultiQuestions,editUserVideos,getUserVideos, getCourseVideos,fetchLMSVideo } from '../../actions/asyncActions';
import { Player, LoadingSpinner, ControlBar, ReplayControl, ForwardControl, VolumeMenuButton, BigPlayButton } from 'video-react';
// import useCountDown from 'react-countdown-hook';
import Checkbox from '@material-ui/core/Checkbox'
import { Modal, ModalHeader,Form,Input,InputGroup, ModalBody, ModalFooter} from "reactstrap"


const Assesments2 = props => {
    const location = props.location
    const section_id = location.state ? location.state.id : 1
    const user = JSON.parse(localStorage.getItem("pm_users"));
    const [Courseid, setCourseid] = useState('');
    const [courseModels, setModels] = useState([]);
    const { history } = props
    const [sections, setSections] = useState([]);
    const [allQuestions, setQuestions] = useState([]); 
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [score, setScore] = useState(0);
    const [clicked, setClicked] = useState(false);
    const [showScore, setShowScore] = useState(false);
    //const currentTimer = allQuestions[currentQuestion] ? allQuestions[currentQuestion].set_timer: 20 ;
    const currentTimer = 60;
    const [avg2, setAvg2] = useState(0);
    const [percent, setPercent] = useState(0);
    //const [currentTimer, setcurrentTimer] = useState()
    const [seconds, setSeconds] = useState(currentTimer)
    const [correct, setCorrect] = useState([]);
    const [checked, setChecked] = useState([]);
    //setCorrect([]);
    console.log("allQuestions",allQuestions)
    console.log("currentQuestion",currentQuestion)
    console.log("allQuestions[currentQuestion]",allQuestions[currentQuestion])
    console.log("currentTimer",currentTimer)
    console.log("seconds",seconds)
    const [wrongId, setWrongId] = useState(null);
    console.log("wrongId",wrongId)
    //const questions = allQuestions.filter(s => s.section_id === secId);
    const[videourl,setVideoUrl] = useState(null);
    const percentage =(avg2/allQuestions.length)*100;
    console.log("percentage",percentage)
    const [courseVideos, setCourseVideos] = useState([]); 
    const [lmsVideo, setLMSvideo] = useState([]);
    const [userVideos, setUserVideos] = useState([]);
    const [subscribemodal, setSubscribemodal] = useState(false)
    const [form, setForm] = useState({});
    const [isActive, setIsActive] = useState(false);
    // useEffect( () => {
    //     if(allQuestions.length > 0 )
    //     {
    //         setcurrentTimer(allQuestions[currentQuestion] ? allQuestions[currentQuestion].set_timer:null)

    // }
        
    // },[currentTimer])
    useEffect( () => {
        getUserVideos()
        .then(resp => setUserVideos(resp.data))

      }, []);

      useEffect(() => {
        setTimeout(() => {
          setSubscribemodal(true)
        }, 500)
      }, []) 


    useEffect( () => {
        getmultiQuestions()
         .then(resp => {
            const filterQuestions = resp.data.filter(x => x.section_id === section_id)
        //   setQuestions(resp.data);
        const result = filterQuestions;
        let arr = [] 
        result.forEach((n,i) => {
            // n.answer_a='a'
            // n.answer_b='b'
            // n.answer_c='c'
            // n.answer_d='d'
            const qId = n.video_id
            const marks=n.marks
            const set_timer=n.set_timer
            const question=n.question
            const correctAns=(n.correct_answer).split(",")//[a,b]
            const answersList=[
                // {answer:n.answer_a, isCorrect: (n.answer_a === n.correct_answer ) ? true : false },
                // {answer:n.answer_b, isCorrect: (n.answer_b === n.correct_answer) ? true : false },
                // {answer:n.answer_c, isCorrect: (n.answer_c === n.correct_answer) ? true : false },
                // {answer:n.answer_d, isCorrect: (n.answer_d === n.correct_answer) ? true : false }
                {answer:n.answer_a, isCorrect: (correctAns.includes('a')) ? true : false, answerLen:correctAns.length},
                {answer:n.answer_b, isCorrect: (correctAns.includes('b')) ? true : false, answerLen:correctAns.length},
                {answer:n.answer_c, isCorrect: (correctAns.includes('c')) ? true : false,answerLen:correctAns.length},
                {answer:n.answer_d, isCorrect: (correctAns.includes('d')) ? true : false,answerLen:correctAns.length}
            ]
            arr.push({
                question:question,
                answersList:answersList,
                marks:marks,
                qId:qId,
                set_timer:set_timer,
                
            })
        })
        setQuestions(arr);
         })
    }, []);
    console.log("questions", allQuestions);



    useEffect( () => {
        getCourseVideos()
        .then(resp => setCourseVideos(resp.data))
      }, []);






    // const questionslist = allQuestions.filter(s => s.section_id === secId)
    
    const getValue=(e,answerOption)=>{
        let res=correct;
        //res.push(e.target.value)//data.iscorrect
        res.push(answerOption.isCorrect)
        setCorrect(res)
        const ansLength = answerOption.answerLen 
        if(ansLength === correct.length)
        {
            handleCorrectAnswer(correct)
        }
        else {
            const data ={
                video_id: allQuestions[currentQuestion].qId,
                 status: 'Incorrect',
             
               }
               console.log("Data", data);
               editUserVideos(data)
               .then(resp => {
                 getUserVideos()
                     .then(resp => setUserVideos(resp.data))
                     console.log("resp",resp.data)
                     // setStatus({ status:'incorrect'})
                   })
                   .catch(err => {
                     console.log("Error", err);
                   })

            //setWrongId(allQuestions[currentQuestion].qId)
        }
        //setShowScore(true);
        setClicked(true);
        //handleCorrectAnswer
        console.log("answerOption",answerOption)
        console.log("ansLength",ansLength)
        //console.log("correct",correct.join())
      }
      console.log("correct",correct) 
      

      const handleCorrectAnswer = (data) => {
        const totalLength = data.length;
        const aaa = data.filter(x => x === true).length;
        const avg = aaa/totalLength;
        setScore(score + avg * +allQuestions[currentQuestion].marks)
        setAvg2( avg2 + +1)
        console.log("totalLength",totalLength)
        console.log("aaa",aaa)
        console.log("avg",avg)
        if(percentage < 65){
            setPercent("BETTER LUCK NEXT TIME!!!")
        }
        else{
            setPercent("WELL DONE!!!")
        }
   
}; 
console.log("score",score)      
    
    

      useEffect(() => {
        if (isActive)  { 
        if (seconds > 0) {
          setTimeout(() => setSeconds(seconds - 1), 1000);
        } else {
          
                    console.log("else part working")
                    setShowScore(true);
        }
                }
                
       
      },[seconds,isActive]);

      const goBack = () => {
        props.history.push(`${process.env.PUBLIC_URL}/courses/course_models`);
     }


     const learnMore = (course_id) => {
        const userVideoFilter = userVideos.filter(s => s.user_id === user.id && s.course_id === course_id && s.status === 'Incorrect' );
        setCourseid(course_id);
        console.log("course_id", course_id);
        console.log("userVideoFilter", userVideoFilter);
        // var videoObj = (userVideoFilter || []).sort((a,b) => {
        //         return b.id-a.id;
        //       });
              var videoObj = userVideoFilter;
        console.log("videoObj", videoObj[0]);
        
        if(videoObj != ''){            

            const id = (videoObj && videoObj[0].video_id) || [];
            //console.log("qqqqq",id)
            const aaaa = courseVideos.find( s => s.id === id );
            console.log("qqqqq",aaaa)
            const Mid = (videoObj && videoObj[0].model_id) || [];

            //const courseId = videoData && videoData.course_id;
            const sectionId = aaaa && aaaa.section_id;
            const findSection = sections.find(a => a.section_id === sectionId);
            const modelId = findSection && findSection.model_id;
            const findModule = courseModels.find(a => a.model_id === modelId);
            const Mname = findModule && findModule.name;
            
            const VideoName = aaaa && aaaa.video_name;
            console.log("aaa", aaaa);
             console.log("Mid", Mid);
            // console.log("Mname", Mname);
            fetchLMSVideo(VideoName)
            .then(resp => 
            {
                setLMSvideo(resp.data);
                console.log("resp", resp);
                console.log("RespData", resp.data);
                props.history.push(`${process.env.PUBLIC_URL}/courses/course_learning`, { id, Mid, Mname, Courseid:course_id, url: resp.url, reapData:resp.data });
            })

        } else {
            props.history.push(`${process.env.PUBLIC_URL}/courses/course_models`, { id: course_id });
        }
       
    
    
 }
    // const [countInTimeout, setCountInTimeout] = useState(0);
    
    // const sum = allQuestions[currentQuestion].marks.reduce(function(prev, current) {
    //     return prev + +current.score
    //   }, 0);

    // const handleCorrectAnswer = (isCorrect) => {
        
        const courseVideosFilter = userVideos.filter(s => s.user_id === user.id &&  s.status === 'Incorrect');
        const foundcourseVideo = courseVideosFilter[0]; 
   

    const handleNextQuestion = () => {
        setClicked(false);
        if(currentQuestion < allQuestions.length - 1){
            setCurrentQuestion(currentQuestion + 1)
            setCorrect([]);
            setClicked(true);
            
        }else{
            setShowScore(true);

            getCourseVideos()
            .then(resp => {
                const findVideo = (resp.data).find(r => r.id === parseInt(wrongId))
                console.log("findVideo",findVideo)

                const videoName =findVideo && findVideo.video_name;

                // setCourseVideos(resp.data))
            //3.
            fetchLMSVideo(videoName)
            .then(resp => 
             {
            setVideoUrl(resp.url);
            
             console.log("RespData", resp.url);
              })
           
        })
            
        }
    };

    return (
         <div className="app-wrapper">

             

             
             {showScore ? (
                 <div>
                    <div className="completed">Completed</div>
                    <div className="score-section">
                        Your Score: {score}
                        {/* /{allQuestions.marks + +allQuestions[currentQuestion].marks})  */}
                    </div>
                    <div className="container-fluid" style={{textAlign:"center",paddingTop:"130px",color:'orange',fontSize:'40px'}}>
                    {percent}
                   
                    </div>
                   
                    {/* <center>
                    <div className="video-position" style={{height:'500px',width:'726px'}}>
                            <Player
                          style={{height:'50px',width:'100px'}}
                              autoPlay playsInline
                              // startTime={50}
                              src={videourl}
                            >
                              <BigPlayButton position="center" />
                              <LoadingSpinner />
                              <ControlBar>
                                <VolumeMenuButton />
                                <ReplayControl seconds={5} order={2.1} />
                                <ForwardControl seconds={5} order={3.1} />
                              </ControlBar>
                            </Player>
                        </div>
                        </center> */}

                    <div className="header-faq" style={{paddingTop:"180px"}}>
                        {/* <h5 className="mb-0">Models</h5> */}
                        <button className="btn btn-pill btn-warning btn-air-warning btn-warning-gradien text-light " onClick={goBack} >Back</button>
                        <span className="btn btn-pill btn-warning btn-air-warning btn-warning-gradien pull-right text-light" onClick ={ ( ) => learnMore(foundcourseVideo.course_id)} >Learn more</span>
                    </div>
                    {/* <div className="header-faq"  >
                    
                      <span className="btn btn-pill btn-warning btn-air-warning btn-warning-gradien text-light" onClick ={ ( ) => learnMore(foundcourseVideo.course_id)} >Learn more</span>
                      
                    </div> */}
                </div>

             ) : (
             <div>

                 <div className="answer-section-wrapper" style={{textAlign:'center',backgroundColor:'none'}}>
                     <div className="question-count pull-left answer-section-wrapper" >
                         Question {currentQuestion + 1} / {allQuestions.length}
                     </div>
                     <div className="timer pull-right mt-0 answer-section-wrapper">
                     <div className="time_text answer-section-wrapper pull-right">Time Left</div>
                     <div className="timer_sec answer-section-wrapper pull-right">{seconds}</div>
                     </div>
                     
                     {allQuestions.length > 0 ?
                     <div className="question">
                         
                         {allQuestions[currentQuestion].question}
                     </div>
                     :null
                    }
                 </div>
                 {/* <div className="answer-section-wrapper pull-centre ">
                     
                         
                         <li  >
                             <button 
                             disabled={clicked}
                             className={`answer-button ${clicked && allQuestions[currentQuestion].answer_d === allQuestions[currentQuestion].correct_answer  ? "correct" : ""
                            }`}
                            onClick={() => handleCorrectAnswer((allQuestions[currentQuestion].answer_d === allQuestions[currentQuestion].correct_answer )? true : false)}>
                                 {allQuestions[currentQuestion].answer_d}
                             </button>
                         </li>
                     
                 </div> */}
                  {allQuestions.length > 0 ?
                 <div className="answer-section-wrapper pull-centre ">

                     {allQuestions[currentQuestion].answersList.map((answerOption) => (
                         <li className="answer-list" key={uuidv4()}>
                               {/* <div  className={`answer-button ${clicked && correct ? "correct" : " " }`} >  */}
                               
                             {/* <Checkbox color="primary"  value={answerOption.answer} onChange={(e)=>getValue(e,answerOption)} value={answerOption.answer}  type="checkbox"/> */}
                            
                             
                           {/* <Label>{answerOption.answer}</Label> */}
                             
                            
                             {/* </div> */}

                             <div className={`answer-button ${clicked && (answerOption.isCorrect && answerOption.answerLen === correct.length) ? "correct" : " " }`} key={uuidv4()}>
                                  <Label>
                                 <input onChange={(e)=>getValue(e,answerOption)}  value={answerOption.answer} type="checkbox"  />
                                 <span> {answerOption.answer}</span>
                                 </Label>
                            </div>


                             {/* </button> */}
                              {/* <button 
                             type="checkbox"
                             value={answerOption.answer}
                             disabled={clicked}
                             onChange={(e)=>getValue(e,answerOption)}
                             className={`answer-button ${clicked && correct ? "correct" : " " }`}
                             
                            
                            onClick={() => handleCorrectAnswer()}
                            >
                                 {answerOption.answer}
                             </button>  */}
                         </li>
                     ))}
                 </div>
                  :null
}
             <div>
                 <button className="btn btn-pill btn-secondary btn-air-secondary  btn-secondary-gradien text-light pull-centre"
                  onClick={handleNextQuestion} 
                  disabled={!clicked}
                  >
                     Next
                 </button>
             </div>
             </div>
             )
             
             }

<Modal 
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal)
        }}
      >
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <ModalHeader
              toggle={() => {
                setSubscribemodal(!subscribemodal)
              }}
            ></ModalHeader>
          </div>
          <div className="modal-body">
          <Form>
                    
          <div className="answer-button1" style={{textAlign:"center",width:"400px",marginLeft:"35px"}}>
            <h6><p7>1.Quiz ends in 60 seconds.</p7></h6>
              <h6><p7>2.You can choose multiple correct answers.</p7></h6>
              <h6><p7>3.Score will be based on the number of correct answer you chose in each question.eg:Question no.1 is of 10 marks,there are 2 correct answers for that particular question.If you chose 1 correct answer among 2 then your score is 10/2 =5</p7></h6>
              <h6><p7>4.Click on the checkboxes on the Button while choosing</p7></h6>
              <div  style={{ textAlign:"center",paddingTop:"120px",marginLeft:"25px"}}>
                <h4>click on start to begin</h4>
              </div>
              </div>
             

                  </Form>
                  <div className="text-center mt-4">
                    <button
                      type="button"
                      className="btn btn-primary "
                      onClick={() => 
                        {setIsActive(true)
                        setSubscribemodal(false)
                        
                        }

                      }
                      
                      >
                        START
                    </button>
                  </div>
           
           </div>
        </div>
      </Modal>
         </div>
    );
};

export default Assesments2;
