import React, { Fragment, useState, useEffect } from 'react';
// import JobFilter from './job-filter';
// import jobDB from '../../data/job/jobDB';
import Breadcrumb from '../common/breadcrumb'
import { getAllCourses, getAllPayments, getUserVideos, fetchLMSVideo, getCourseVideos, getSections,
        getModels } from '../../actions/asyncActions';

const JobList = (props) => {
    const user = JSON.parse(localStorage.getItem("pm_users"));

    const [courses, setCourses] = useState([]);  

    useEffect( () => {
        getAllCourses()
        .then(resp => setCourses(resp.data))
      }, []);

    const [payments, setPayment] = useState([]);

    useEffect( () => {
         getAllPayments()
         .then(resp => {
          setPayment(resp.data);
         })
    }, []);

    const [userVideos, setUserVideos] = useState([]); 
    useEffect( () => {
        getUserVideos()
        .then(resp => setUserVideos(resp.data))
      }, []);

    const [courseVideos, setCourseVideos] = useState([]);
    const [lmsVideo, setLMSvideo] = useState([]);
    useEffect( () => {
        getCourseVideos()
        .then(resp => setCourseVideos(resp.data))
      }, []);

    const [Courseid, setCourseid] = useState('');
    const [sections, setSections] = useState([]);
    useEffect( () => {
        getSections(Courseid)
        .then(resp => setSections(resp.data))
      }, []);

    const [courseModels, setModels] = useState([]);
    useEffect( () => {
        getModels(Courseid)
        .then(resp => setModels(resp.data))
      }, []);

    const paymentsFilter = payments.filter(s => s.user_id === user.id);

    const handleSubmit = (course_id) => {
        const userVideoFilter = userVideos.filter(s => s.user_id === user.id && s.course_id === course_id );
        setCourseid(course_id);
        console.log("course_id", course_id);
        console.log("userVideoFilter", userVideoFilter);
        var videoObj = (userVideoFilter || []).sort((a,b) => {
                return b.id-a.id;
              });
        console.log("videoObj", videoObj[0]);
        
        if(videoObj != ''){            

            const id = (videoObj && videoObj[0].video_id) || [];
            
            const aaaa = courseVideos.find( s => s.id === id );
            const Mid = (videoObj && videoObj[0].model_id) || [];

            //const courseId = videoData && videoData.course_id;
            const sectionId = aaaa && aaaa.section_id;
            const findSection = sections.find(a => a.section_id === sectionId);
            const modelId = findSection && findSection.model_id;
            const findModule = courseModels.find(a => a.model_id === modelId);
            const Mname = findModule && findModule.name;
            
            const VideoName = aaaa && aaaa.video_name;
            console.log("aaa", aaaa);
             console.log("Mid", Mid);
            // console.log("Mname", Mname);
            fetchLMSVideo(VideoName)
            .then(resp => 
            {
                setLMSvideo(resp.data);
                console.log("resp", resp);
                console.log("RespData", resp.data);
                props.history.push(`${process.env.PUBLIC_URL}/courses/course_learning`, { id, Mid, Mname, Courseid:course_id, url: resp.url, reapData:resp.data });
            })

        } else {
            props.history.push(`${process.env.PUBLIC_URL}/courses/course_models`, { id: course_id });
        }
        
        console.log("handleSubmit",handleSubmit) 
    }
    

    const clickCourseDetail = (n) => {
        props.history.push(`${process.env.PUBLIC_URL}/courses/course_detail`, { id: n.course_id });
    }

    return (
        <Fragment>
            <Breadcrumb title="My Courses" parent="Courses" />
            <div className="container-fluid" >
                <div className="row">
                    <div className="col-xl-12 xl-60" >
                        {paymentsFilter.map((n, i) => {
                            const aaa = courses.find(s => s.id === n.course_id);
                            const courseName = aaa && aaa.course_name;
                            const coursePrice = aaa && aaa.price;
                            const badgeType = aaa && aaa.badgeType;
                            const Area = aaa && aaa.area;
                            const Country = aaa && aaa.country;
                            const Shortnote = aaa && aaa.short_note;

                            return (
                                <div className={`card ${n.badgeValue ? '' : 'ribbon-vertical-left-wrapper'}`} key={i}>
                                    <div className="job-search">
                                        <div className="card-body ">
                                            <div className="media">
                                                <img className="img-40 img-fluid m-r-20" src={require('../../assets/images/job-search/1.jpg')} alt="" />
                                                <div className="media-body">
                                                    <h6 className="f-w-600"><a href="#javascript">{courseName}</a>
                                                        {(badgeType === 'primary' ? 
                                                            <div>
                                                                <span className="btn btn-pill btn-warning btn-air-warning btn-warning-gradien pull-right text-light"  style={{background: `linear-gradient(to right,  #ec9f05 0%,#ff4e00 100%)`,cursor: "pointer"}} onClick={ () => handleSubmit(n.course_id)}>
                                                                    Start Learning
                                                                </span>
                                                               {/* <span className="badge badge-warning pull-right text-light" style={{cursor: "pointer"}} onClick={ () => clickCourseDetail(n)}>
                                                                    Know More
                                                                </span> &nbsp; &nbsp; */}
                                                            </div>
                                                            : 
                                                            <span className="btn btn-pill btn-warning btn-air-warning btn-warning-gradien pull-right text-light" style={{cursor: "pointer"}} onClick={ () => handleSubmit(n.course_id)}>
                                                                Start Learning
                                                            </span>
                                                        )}
                                                    </h6>
                                                    <p>{Area}, {Country}
                                                        <span>
                                                            <i className="fa fa-star font-warning"></i>
                                                            <i className="fa fa-star font-warning"></i>
                                                            <i className="fa fa-star font-warning"></i>
                                                            <i className="fa fa-star font-warning"></i>
                                                            <i className="fa fa-star font-warning"></i>
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <p>
                                                {Shortnote}
                                                <span className="btn btn-pill btn-secondary btn-air-secondary btn-xs btn-secondary-gradien text-light pull-right" 
                                                    style={{cursor: "pointer"}} onClick={ () => clickCourseDetail(n)}>
                                                    Know More
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default JobList;