import React, { useState ,Fragment, useEffect } from "react"
// import MetaTags from 'react-meta-tags';
import {TextField, Backdrop , CircularProgress, TablePagination} from '@material-ui/core';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap"
//import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link } from "react-router-dom"
//import Select from "react-select"
// const animatedComponents = makeAnimated()
import Select from "react-select"
import {Autocomplete} from '@material-ui/lab';
import Breadcrumb from '../common/breadcrumb';
import Checkbox from '@material-ui/core/Checkbox'
import { getmultiQuestions, postmultiQuestions,getAllCourses,getCourseVideos,getSections, getAllSections, uploadCourseDocs, getCourseDocs, postCourseDocs } from '../../actions/asyncActions';

// import { postQuestions, getQuestions,   } from '../../actions/asyncActions';
import { toast } from 'react-toastify';


const FormAdvanced = (props) => {
    // const [questions, setQuestions] = useState([]);
    const location = props.location
    const [addQuestions, setAddQuestions]= useState([]);
    const [questionsList, setQuestions] = useState([]);
    const [ischecked, setIsChecked] = useState(false); 
    const [values, setValues] = useState([]);
     console.log("values",values)
     
  
   
 
     const [durations, setDurations] = useState([]);
     console.log("durations",durations)

     const [courses, setCourses] = useState([]);  

     useEffect( () => {
         getAllCourses()
         .then(resp => setCourses(resp.data))
       }, []);

       const user = JSON.parse(localStorage.getItem("pm_users"));

      //  const Courseid = location.state.Courseid;
       const [course_val, setCourseVal] = useState(null);
       const id = location.state ? location.state.id : 1
       const selectedCourseId = course_val && course_val.id;
       const aaa = course_val && course_val.course_name;
       let bbb = aaa;
       var selectedCourseName = (bbb || '').toUpperCase();

      const [section_val, setSectionVal] = useState(null);
      const selectedSectionId = section_val && section_val.id;
      const [docfile, setDocFile] = React.useState(null);

      const [sections, setSections] = useState([]);
      const [allcheckbox, setCheckboxes] = useState([]);
      //console.log("allcheckbox",allcheckbox)
      const [video_val, setVideo_val] = useState(null)
      const [courseVideos, setCourseVideos] = useState([]);
      const selectedVideoId = video_val && video_val.id;
      
      useEffect( () => {
      getAllSections()
      .then(resp => setSections(resp.data))
      }, []);

      const filterSections = sections.filter(s => s.course_id === selectedCourseId);

      const [course_docs, setCourseDocs] = useState([]);

      useEffect( () => {
      getCourseDocs()
      .then(resp => setCourseDocs(resp.data))
      }, []);

      useEffect( () => {
        getCourseVideos()
        .then(resp => setCourseVideos(resp.data))
      }, []);
  
      // useEffect( () => {
      //   getSections(Courseid)
      //   .then(resp => setSections(resp.data))
      // }, []);

    //  useEffect(async () => {
    //     await getQuestions()
    //       .then(resp => {
    //         console.log("message", resp)
    //         setQuestions(resp)
    //       })
    //   }, []);

const getValue=(e)=>{
  let data=allcheckbox;
  let length=(allcheckbox.length)+1;
  data.push(e.target.value)//data.iscorrect
  setCheckboxes(data)
  setIsChecked(!ischecked);
  // console.warn(allcheckbox)
  console.log("allcheckbox",allcheckbox.join())
  console.log("length",length)
  
};



    const handleChecked= (e) => {
      setCheckboxes({ ...allcheckbox, [e.target.name]: e.target.checked})
      
    }







      const onInputChange = e => {
        setValues({ ...values, [e.target.name]: e.target.value });
      };

    //  const handleChecked= (event) => {
    //   const isChecked = event.target.checked;
    //   if(isChecked){
    //     setCheckboxes({ correct_answer: [...this.state.correct_answer, event.target.value] });
    //   }else{
    //       let index = this.state.correct_answer.indexOf(event.target.value);
    //       this.state.correct_answer.splice(index, 1);
    //       this.setState({ correct_answer: this.state.correct_answer });
    //   }
        
    //   }
      
     
      // const onAddChange = e => {
      //   setDurations({ ...durations, [e.target.name]: e.target.duration });
      // };

      // const handleAdd = () => {
      //   const timers={
      //     marks:durations.marks,
      //     set_timer:durations.set_timer
      //   }
      // }

      const handleSubmit = () => {
        
        const multiquestions = {
          question: values.question,
          answer_a: values.answer_a,
          answer_b: values.answer_b,
          answer_c: values.answer_c,
          answer_d: values.answer_d,
          //correct_answer:allcheckbox.correct_answer ? 'a' :'' && allcheckbox.correct_answer ? 'b' :'' && allcheckbox.correct_answer ? 'c' :''&& allcheckbox.correct_answer ? 'd' :'',
          correct_answer: allcheckbox.join(),
          marks: values.marks,
          set_timer: values.set_timer,
          section_id: selectedSectionId,
          course_id: selectedCourseId,
          video_id:selectedVideoId,
          user_id: user.id
          
        }
        console.log("handleSubmit",multiquestions)
        
     
    
        postmultiQuestions(multiquestions)
          .then(resp => {
            getmultiQuestions()
            .then(resp => setQuestions(resp.multiquestions))
            
            setCourseVal(null)
            setSectionVal(null)
            setDocFile(null)
            //setQuestions({question:'',answer_a:'',answer_b:'',answer_c:'',answer_d:''});
    
            toast.success("Question added successfully", {
              position:
                toast.POSITION.TOP_CENTER, autoClose: 2000
            })
            
          })
    
    
          .catch(err => {
            console.log("Error", err);
          })
    
      }
      
    return (
        <Fragment>
      <Breadcrumb title="Add multiple assesment" parent="Form" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-xl-12">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                      <h5>Add Question</h5>
                    {/* <h5>Default Form Layout</h5><span>Using the <a href="#javascript">card</a> component, you can extend the default collapse behavior to create an accordion.</span> */}
                  </div>
                  <CardBody>
                  <div className="col-md-6">
                      <Autocomplete
                        freeSolo
                        options={courses}
                        getOptionLabel={option => option.course_name}
                        value={course_val}                      
                        onChange={(event, newValue) => {
                          setCourseVal(newValue);                          
                        }}            
                        renderInput={params => (
                          <TextField {...params} label="Course" margin="normal" variant="outlined" helperText="Please select Course"  />
                        )}
                      />
                    </div>
                    { selectedCourseId ?
                      <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6 ">
                          <Autocomplete 
                            freeSolo
                            options={filterSections}
                            getOptionLabel={option => option.section_name}
                            value={section_val}                      
                            onChange={(event, newValue) => {
                              setSectionVal(newValue);
                            }}            
                            renderInput={params => (
                              <TextField {...params} label="Section" margin="normal" variant="outlined" helperText="Please select Section" fullWidth />
                            )}
                          />
                      </div>
                      </div>
                      </div>
                      : null
                      }




                  {/* <Col md={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-aircraft-Input">Question No</Label>
                          <Input
                             type="number"
                            className="form-control"
                            value={values.id}
                              onChange={onInputChange}
                            name="id"
                            id="text" 
                           
                          />
                        </div>
                      </Col> */}
                  <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-aircraft-Input">Question</Label>
                          <Input
                             type="textarea"
                            className="form-control"
                            value={values.question}
                              onChange={onInputChange}
                            name="question"
                            id="textarea" 
                            rows="3"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-aircraft-Input">answer a</Label>
                          <Input
                             type="textarea"
                            className="form-control"
                            value={values.answer_a}
                              onChange={onInputChange}
                            name="answer_a"
                            id="textarea" 
                            rows="2"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-aircraft-Input">answer b</Label>
                          <Input
                             type="textarea"
                            className="form-control"
                            value={values.answer_b}
                            onChange={onInputChange}
                            name="answer_b"
                            id="textarea" 
                            rows="2"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-aircraft-Input">answer c</Label>
                          <Input
                             type="textarea"
                            className="form-control"
                            value={values.answer_c}
                            onChange={onInputChange}
                            name="answer_c"
                            id="textarea" 
                            rows="2"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-aircraft-Input">answer d</Label>
                          <Input
                             type="textarea"
                            className="form-control"
                            value={values.answer_d}
                            onChange={onInputChange}
                            name="answer_d"
                            id="textarea" 
                            rows="2"
                          />
                        </div>
                        <div>
                        <Label>Choose the correct options</Label>
                        <div>
                        <Label>A</Label>
                        <Checkbox color="primary" value="a" onChange={(e)=>getValue(e)}/>
                        </div>
                        <div>
                        <Label>B</Label>
                        <Checkbox color="primary" value="b" onChange={(e)=>getValue(e)}/>
                        </div>
                        <div>
                        <Label>C</Label>
                        <Checkbox color="primary"  value="c" onChange={(e)=>getValue(e)}/>
                        </div>
                        <div>
                        <Label>D</Label>
                        <Checkbox color="primary" value="d" onChange={(e)=>getValue(e)}/>
                        </div>
                      </div>
                      </Col>
                      {/* <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-aircraft-Input">correct answer</Label>
                          <Input
                             type="textarea"
                            className="form-control"
                            value={values.correct_answer}
                            onChange={onInputChange}
                            name="correct_answer"
                            id="textarea" 
                            rows="2"
                          />
                        </div>
                      </Col> */}
                      <div>
                      <Col md={2}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-aircraft-Input">Marks</Label>
                          <Input
                             type="number"
                            className="form-control"
                            value={values.marks}
                            onChange={onInputChange}
                            name="marks"
                            id="number" 
                            
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-aircraft-Input">set timer</Label>
                          <Input
                             type="number"
                            className="form-control"
                            value={values.set_timer}
                            onChange={onInputChange}
                            name="set_timer"
                            id="number" 
                           
                          />
                        </div>
                      </Col>
                      
                      </div>
                      

                      
                       
                   <div className="col-md-6">
                      <Autocomplete
                        freeSolo
                        options={courseVideos}
                        getOptionLabel={option => option.video_name}
                        value={video_val}                      
                        onChange={(event, newValue) => {
                          setVideo_val(newValue);                          
                        }}            
                        renderInput={params => (
                          <TextField {...params} label="recommend video" margin="normal" variant="outlined" helperText="Please select videos"  />
                        )}
                      />
                    </div>

                      </CardBody>
                 
                  <div className="card-footer">
                    <button className="btn btn-primary mr-1"
                    onClick={handleSubmit}
                    >Submit</button>
                    
                  </div>
                </div>
              </div>

</div>
</div>
</div>
</div>
</Fragment>














                               
    )
}

export default FormAdvanced

