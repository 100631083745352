import React from 'react'


function timeout_page() {
    return (
        <div style={{textAlign:'center'}}>
       
        <div className="mb-3" >
          <i className="display-4 text-muted bx bxs-cloud-upload" />
        </div>
        <h1>Oops timeout!!</h1>
      </div> 
    )
}

export default timeout_page
