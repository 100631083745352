const data = [
  {
    src: require('../../assets/images/masonry/1.jpg'),
    width: 4,
    height: 3
  },
  {
    src: require('../../assets/images/masonry/2.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../../assets/images/masonry/3.jpg'),
    width: 3,
    height: 4
  },
  {
    src: require('../../assets/images/masonry/4.jpg'),
    width: 3,
    height: 4
  },
  {
    src: require('../../assets/images/masonry/5.jpg'),
    width: 3,
    height: 4
  },
  {
    src: require('../../assets/images/masonry/11.jpg'),
    width: 4,
    height: 3
  },
  {
    src: require('../../assets/images/masonry/7.jpg'),
    width: 3,
    height: 4
  },
  {
    src: require('../../assets/images/masonry/8.jpg'),
    width: 4,
    height: 3
  },
  {
    src: require('../../assets/images/masonry/9.jpg'),
    width: 4,
    height: 3
  },
  {
    src: require('../../assets/images/masonry/10.jpg'),
    width: 3,
    height: 4
  },
  {
    src: require('../../assets/images/masonry/11.jpg'),
    width: 4,
    height: 3
  },
  {
    src: require('../../assets/images/masonry/12.jpg'),
    width: 4,
    height: 3
  },
  {
    src: require('../../assets/images/masonry/13.jpg'),
    width: 4,
    height: 3
  },
  {
    src: require('../../assets/images/masonry/14.jpg'),
    width: 4,
    height: 3
  },
  {
    src: require('../../assets/images/masonry/15.jpg'),
    width: 4,
    height: 3
  },
]

export default data;