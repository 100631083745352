import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { TablePagination } from '@material-ui/core';
import { getAllUser, updateUsers, postUsers, deleteUsers } from '../../actions/asyncActions';

const MyUser = () => {
    const user = JSON.parse(localStorage.getItem("pm_users"));
    const [person, setPerson] = useState([]);
    const initialFormState = { id: '', firstname:'', lastname:'', username:'', password:'', email:'', phone:'', role:''}
    const [ cu_user, setCurrentUser ] = useState(initialFormState)
    const [modal, setModal] = useState();
    const [addmodal, setAddModal] = useState();
    const [values, setValues] = useState({});

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    useEffect( () => {
     getAllUser()
     .then(resp => {
      setPerson(resp.data);
     })
    }, []);

    const handleChange = event => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
    };

    const handleInputChange = event => {
        const { name, value } = event.target
        setCurrentUser({ ...cu_user, [name]: value })
    }

    const addRow = () => {
     setAddModal(!addmodal)
    }

    const editRow = user => {
     setModal(!modal)
     setCurrentUser({ id: user.id, firstname: user.firstname, lastname: user.lastname, username: user.username, 
       password:user.password, email:user.email, phone:user.phone, role:user.role })
    }

    const handleSubmit = () => {
        const userss = JSON.parse(localStorage.getItem("pm_users"));
        const adduser = {
          firstname: values.firstname,
          lastname: values.lastname,
          username: values.username,
          email: values.email,
          phone: values.phone,
          password: values.password,
          role: values.role,
        }
        console.log("adduser", adduser);
        postUsers(adduser)
        .then(resp => {
            getAllUser()
            .then(resp => setPerson(resp.data))
            setAddModal(!addmodal)
          })
          .catch(err => {
            console.log("Error", err);
        })
   }

  let userObj = {};
  userObj = (person || []).sort((a,b) => {
    return b.id-a.id;
  });
  console.log("userObj", userObj[0]);

    const handleUpdate = (id, data) => {
       // const user = JSON.parse(localStorage.getItem("pm_users"));
        const bbb = {
          id: id,
          firstname: data.firstname,
          lastname: data.lastname,
          username: data.username,
          password: data.password,
          email: data.email,
          phone: data.phone,
          role: data.role
        }
        updateUsers(bbb)
        .then(resp => {
              getAllUser()
              .then(resp => setPerson(resp.data))
            })
            .catch(err => {
              console.log("Error", err);
            })
        setModal(!modal)
    }

    const deleteRow = (n) => {
      console.log("user_id", n.id)
      const aaa = {
        id: n.id
      }
      deleteUsers(aaa)
      .then(resp => {
        getAllUser()
        .then(resp => {
          setPerson(resp.data);
        });
      })
      .catch(err => {
        console.log("Error", err);
      })
    };

        return (
            <Fragment>
            <Breadcrumb title="All Users" parent="Users" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-sm table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th scope="col">
                                                     <input className="checkbox_animated" type="checkbox" />
                                                    </th>
                                                    <th scope="col">#</th>
                                                    <th scope="col">First Name</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Phone</th>
                                                    <th scope="col">Username</th>
                                                    <th scope="col">Role</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {person.filter(s => s.role !== 'superadmin')
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((n, i)=> {
                                                  return (
                                                    <tr>
                                                     <td> <input className="checkbox_animated" type="checkbox" /> </td>
                                                     <th scope="row">{i+1}</th>
                                                     <td>{n.firstname}</td>
                                                     <td>{n.email}</td>
                                                     <td>{n.phone}</td>
                                                     <td>{n.username}</td>
                                                     <td>{n.role}</td>
                                                     <td>
                                                     {/* <button className="btn btn-info btn-xs" type="button" onClick={() => editRow(n)}>
                                                       <i className="icofont icofont-edit"></i>
                                                      </button> */}
                                                      <i className="fa fa-edit" onClick={() => editRow(n)} style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}
                                                      ></i>
                                                      <i className="fa fa-trash" onClick={() => deleteRow(n)} style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}
                                                      ></i>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="card" style={{paddingTop:'20px'}}>
                            <div className="card-block row">
                                <div className="col-sm-6 col-lg-6 col-xl-6">
                                 <button className="btn btn-pill btn-secondary btn-air-secondary btn-sm btn-secondary-gradien text-light"  type="button" onClick={() => addRow()}
                                   >
                                   Add User
                                  </button>
                                </div>
                                <div className="col-sm-6 col-lg-6 col-xl-6">
                                  <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={person.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                   />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* < !-- Using Form Modal for Add Users --> */}
            <Modal isOpen={addmodal} toggle={addRow}>
                <ModalHeader toggle={addRow}>Add User</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Firstname:</label>
                              <input className="form-control" name="firstname" type="text" placeholder="First name" 
                               value={values.firstname} onChange={handleChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Lastname:</label>
                              <input className="form-control" name="lastname" type="text" placeholder="Last name" 
                               value={values.lastname} onChange={handleChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Username:</label>
                              <input className="form-control" name="username" type="text" placeholder="Username" 
                               value={values.username} onChange={handleChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Password:</label>
                              <input className="form-control" name="password" type="password" placeholder="Password" 
                               value={values.password} onChange={handleChange}/>
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Email:</label>
                              <input className="form-control" name="email" type="email" placeholder="Email" 
                               value={values.email} onChange={handleChange}/>
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Phone:</label>
                              <input className="form-control" name="phone" type="number" placeholder="Phone" 
                               value={values.phone} onChange={handleChange}/>
                            </div>
                            <div className="col">
                              <div className="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                <label className="col-form-label" htmlFor="message-text">Role:</label>
                                <div className="radio radio-primary">
                                  <input id="radioinline1" type="radio" name="role" value="super_admin" onChange={handleChange} />
                                  <label className="mb-0" for="radioinline1">Super Admin</label>
                                </div>
                                <div className="radio radio-primary">
                                  <input id="radioinline2" type="radio" name="role" value="admin" onChange={handleChange} />
                                  <label className="mb-0" for="radioinline2">Admin</label>
                                </div>
                                <div className="radio radio-primary">
                                  <input id="radioinline3" type="radio" name="role" value="teacher" onChange={handleChange} />
                                  <label className="mb-0" for="radioinline3">Teacher</label>
                                </div>
                                <div className="radio radio-primary">
                                  <input id="radioinline4" type="radio" name="role" value="student" onChange={handleChange} />
                                  <label className="mb-0" for="radioinline4">Student</label>
                                </div>
                              </div>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit} >Submit</Button>
                    <Button color="secondary" onClick={addRow}>Cancel</Button>
                </ModalFooter>
            </Modal>

            {/* < !-- Using Form Modal for Edit Users --> */}
            <Modal isOpen={modal} toggle={editRow}>
                <ModalHeader toggle={editRow}>Edit User</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Firstname:</label>
                              <input className="form-control" name="firstname" type="text" placeholder="First name" 
                               value={cu_user.firstname} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Lastname:</label>
                              <input className="form-control" name="lastname" type="text" placeholder="Last name" 
                               value={cu_user.lastname} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Username:</label>
                              <input className="form-control" name="username" type="text" placeholder="Username" 
                               value={cu_user.username} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Password:</label>
                              <input className="form-control" name="password" type="password" placeholder="Password" 
                               value={cu_user.password} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Email:</label>
                              <input className="form-control" name="email" type="email" placeholder="Email" 
                               value={cu_user.email} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Phone:</label>
                              <input className="form-control" name="phone" type="number" placeholder="Phone" 
                               value={cu_user.phone} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-12 mb-3">
                              <div className="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                <label className="col-form-label" htmlFor="message-text">Role:</label>
                                <div className="radio radio-primary">
                                  <input id="radioinline1" type="radio" name="role" value="super_admin" onChange={handleInputChange} />
                                  <label className="mb-0" for="radioinline1">Super Admin</label>
                                </div>
                                <div className="radio radio-primary">
                                  <input id="radioinline2" type="radio" name="role" value="admin" onChange={handleInputChange} />
                                  <label className="mb-0" for="radioinline2">Admin</label>
                                </div>
                                <div className="radio radio-primary">
                                  <input id="radioinline3" type="radio" name="role" value="teacher" onChange={handleInputChange} />
                                  <label className="mb-0" for="radioinline3">Teacher</label>
                                </div>
                                <div className="radio radio-primary">
                                  <input id="radioinline4" type="radio" name="role" value="student" onChange={handleInputChange} />
                                  <label className="mb-0" for="radioinline4">Student</label>
                                </div>
                              </div>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={ () => handleUpdate(cu_user.id, cu_user)} >Save Changes</Button>
                    <Button color="secondary" onClick={editRow}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
        );
}

export default MyUser;