import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import LearningFilter from './learning-filter';
import learningDB from '../../data/learning/learningDB';
import { fetchLMSlist, fetchLMSVideo } from '../../actions/asyncActions';
import Img from '../../assets/images/faq/1.jpg';


const LearningList = (props) => {

    const [lmsVideo, setLMSvideo] = useState([]);

    const [lmsList, setLMSlist] = useState([]);  

    useEffect( () => {
        fetchLMSlist()
        .then(resp => setLMSlist(resp.data))
      }, []);

    console.log("lmsList", lmsList);

    const clickApply = (n) => {
        const id = n.Id
        const CourseName = n.name;
        console.log("CourseName", CourseName);
        fetchLMSVideo(CourseName)
        .then(resp => 
        {
            setLMSvideo(resp.data);
            props.history.push(`${process.env.PUBLIC_URL}/learning/learning-detail`, { id, url: resp.url });
        })

    }

    // const clickApply = (course) => {
    //     const id = course.Id
    //     props.history.push(`${process.env.PUBLIC_URL}/learning/learning-detail`, { id });
    // }

    return (
        <Fragment>
            <Breadcrumb title="Learning List" parent="Learning" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-9 xl-60">
                        <div className="row">
                            {lmsList.map((n, i) => {
                                return (
                                    <div className="col-xl-4 xl-50 col-sm-6" key={i} onClick={ () => clickApply(n)}>
                                        <div className="card">
                                            <div className="blog-box blog-grid text-center product-box">
                                                <div className="product-img">
                                                    <img className="img-fluid top-radius-blog" src={Img} alt="" />
                                                    <div className="product-hover">
                                                        <ul>
                                                            <li><i className="icon-link" onClick={() => clickApply(n)}></i></li>
                                                            <li><i className="icon-import"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="blog-details-main">
                                                    <ul className="blog-social">
                                                        <li className="digits">{n.last_modified}</li>
                                                        <li className="digits">by: {n.writer}</li>
                                                        <li className="digits">{n.hits} Hits</li>
                                                    </ul>
                                                    <hr />
                                                    <h6 className="blog-bottom-details">{n.name}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <LearningFilter />
                </div>
            </div>
        </Fragment>
    );
};

export default LearningList;